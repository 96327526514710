import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Operator } from '../models/operator'
import { EnvConfigurationService } from './env-config.service';

@Injectable({
  providedIn: 'root'
})
export class OperatorSessionService {
  operatorList: Operator[];
  constructor(
    private httpService: HttpService,
    private _envSetting: EnvConfigurationService) { }

  /**
   * Method to get all controlHistory
   *
   */
  async getSessionOperators(deviceId?: number) {
    let url = `${this._envSetting.settings.apiBase}/operators/session/list/`+ deviceId;

    try {
      const response = await this.httpService.get(url)
      .toPromise()

      return response.json() as Operator[]
    } catch (error) {
      return [] as Operator[]
    }
}
}
