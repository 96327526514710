import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { WorkProcessInstance } from '../models/work-process';
import { EnvConfigurationService } from './env-config.service';


@Injectable()
export class WorkProcessService {
  constructor(
    private _http: HttpService,
    private _envSetting: EnvConfigurationService
  ) { }

  async getProcess(processId: number) {
    const url = `${this._envSetting.settings.apiBase}/workprocess/process/${processId}`;

    const response = await this._http.get(url).toPromise();

    return response.json() as WorkProcessInstance;
  }
}