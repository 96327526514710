import {DevicesService} from '../../../services/devices.service';
import {Router} from '@angular/router';
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Device} from '../../../models/device';
import {DeviceBoxComponent} from '../../ui/device-box/device-box.component';
import {MqttService} from '../../../services/mqtt.service';
import {LoginService} from '../../../services/login.service';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';
import { LocalStorageUtils } from 'app/services/localstorage.utils';

@Component({
    templateUrl: './aggregates.component.html',
    styleUrls: ['./aggregates.component.scss']
})
export class AggregatesComponent extends PageWithLoader implements OnInit, OnDestroy {

    @ViewChild('deviceBox') deviceBox: DeviceBoxComponent;
    devices: Device[];
    loading = true;
    gridNumber: number;
    hideButtons = false;
    buttonTimeout: any;
    sliderInterval: any;
    selectedPage = 1;
    pageNumber = 1;
    pageArray: number[];
    isFullScreen = false;
    showDisconnected = false;
    constructor(
        private router: Router,
        private _devicesService: DevicesService,
        public _mqttService: MqttService,
        private _localStorageUtils: LocalStorageUtils,
        _themeService: ThemeService
    ) {
        super(_themeService);
    }


    async ngOnInit() {
        this.showDisconnected = (this._localStorageUtils.showDisconnected === true);

        await this.getData(this.showDisconnected);

        if (localStorage.getItem("token") && !this._mqttService.isConnected()) {
            try {
                await this._mqttService.connect(this._localStorageUtils.readFromLocalStorage('user'), this._localStorageUtils.readFromLocalStorage('token'));
            } catch (err) {
                console.error(err);
            }
        }
    }

    ngOnDestroy() {
    }

    /**
    * Function to navigate to another view
    *
    * @param {any} path
    * @memberof RealtimeComponent
    */
    navigate(path) {
        this.router.navigateByUrl(path);
    }

    /**
    * Function to get data based on the selected filter and draw the chart.
    *
    * @memberof HomeComponent
    */
    async getData(show) {
        this.loading = true;
        let tmpDevices;
        tmpDevices = await this._devicesService.getDevices();
        if (!show) {
            tmpDevices = tmpDevices.filter((device) => device.state > 0);
            this.showDisconnected = false;
        } else {
            this.showDisconnected = true;
        }
        this._localStorageUtils.showDisconnected = this.showDisconnected;

        tmpDevices.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        tmpDevices.sort((a, b) => {
            if (a.state > 0 && b.state === 0) {
                return -1;
            }
            if (a.state === 0 && b.state > 0) {
                return 1;
            }
            return 0;
        });
        this.devices = tmpDevices;
        this.pageArray = [];
        if (this.devices.length > 4) {
            this.gridNumber = 6;
            this.pageNumber = (this.devices.length / 6) > Math.floor(this.devices.length / 6) ? Math.floor(this.devices.length / 6) + 1 : Math.floor(this.devices.length / 6);
            for (let i = 0; i < this.pageNumber; i++) {
                this.pageArray.push(i + 1);
            }
        } else {
            this.gridNumber = 4;
            this.pageArray = [1];
            this.pageNumber = 1;
        }
        this.selectedPage = 1;
        this.loading = false;
    }

    @HostListener('mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        this.resetTimeout();
    }

    resetTimeout() {
        this.hideButtons = false;
        if (this.buttonTimeout) {
            clearTimeout(this.buttonTimeout);
        }
        if (this.sliderInterval) {
            clearInterval(this.sliderInterval);
        }
        this.buttonTimeout = setTimeout(() => {
            this.hideButtons = true;
            this.sliderInterval = setInterval(() => {
                if (this.selectedPage === this.pageNumber) {
                    this.selectedPage = 1;
                } else {
                    this.selectedPage++;
                }
            }, 30000);
        }, 5000);
    }

    openFullScreen() {
        // Trigger fullscreen
        const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
          mozRequestFullScreen(): Promise<void>;
          webkitRequestFullscreen(): Promise<void>;
          msRequestFullscreen(): Promise<void>;
        };

        if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
          docElmWithBrowsersFullScreenFunctions.requestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
          docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
        } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
          docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
        } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
          docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
        }
        this.isFullScreen = true;
      }
      closeFullScreen(){
        const docWithBrowsersExitFunctions = document as Document & {
          mozCancelFullScreen(): Promise<void>;
          webkitExitFullscreen(): Promise<void>;
          msExitFullscreen(): Promise<void>;
        };
        if (docWithBrowsersExitFunctions.exitFullscreen) {
          docWithBrowsersExitFunctions.exitFullscreen();
        } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
          docWithBrowsersExitFunctions.mozCancelFullScreen();
        } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
          docWithBrowsersExitFunctions.webkitExitFullscreen();
        } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
          docWithBrowsersExitFunctions.msExitFullscreen();
        }
        this.isFullScreen = false;
      }

    prevPage() {
        if (this.selectedPage > 1) {
            this.selectedPage--;
        }
        this.resetTimeout();
    }

    nextPage() {
        if (this.selectedPage < this.pageNumber) {
            this.selectedPage++;
        }
        this.resetTimeout();
    }
};