import { Injectable } from '@angular/core';
import { Http, RequestOptions, RequestOptionsArgs, Response, Headers } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { UserUtils } from './users.utils';

type Constructor<T> = new (...args: any[]) => T;

@Injectable()
export class HttpService {
  private defaultOptions: any;

  constructor(private http: Http, 
      private router: Router,
      private _userUtils: UserUtils) {
    this.defaultOptions = {};
  }

  /**
   * set headers to use for every request
   *
   * @param {*} headers
   *
   * @memberOf HttpService
   */
  setDefaultHeaders(headers: any): void {
    this.defaultOptions.headers = headers;
  }

  private addDefaultOptions(options) {
    if (!options) {
      options = new RequestOptions(this.defaultOptions);
    } else {
      options.headers = this.defaultOptions.headers;
    }
    return options;
  }

  getRequestOptionArgs(options?: RequestOptionsArgs): RequestOptionsArgs {
    if (options == null) {
      options = new RequestOptions();
    }
    if (options.headers == null) {
      options.headers = new Headers();
    }
    options.headers.append('Content-Type', 'application/json');
    return options;
  }

  isSatelliteError(err) {
    try {
      if (err && err._body) {
        const parsedBody = JSON.parse(err._body);

        return parsedBody.code && parsedBody.code == "device_unauthorized";
      }
    } catch (ex) {
      return false;
    }
  }
  intercept(observable: Observable<Response>): Observable<Response> {
    return observable.catch((err, source) => {
      const satError = this.isSatelliteError(err) && this._userUtils.isAdmin();
      
      if (err.status === 401 && !err.url.endsWith('/login') && !satError) {
        this.router.navigateByUrl('/login');
        return Observable.throw(err);
      } else {
        return Observable.throw(err);
      }
    });
  }

  get(url: string, options?: RequestOptionsArgs): Observable<Response> {
    options = this.addDefaultOptions(options);
    return this.intercept(this.http.get(url, options));
  }
  post(url: string, body?: any, options?: RequestOptionsArgs): Observable<Response> {
    options = this.addDefaultOptions(options);
    return this.http.post(url, body, options);
  }
  put(url: string, body?: any, options?: RequestOptionsArgs): Observable<Response> {
    options = this.addDefaultOptions(options);
    return this.intercept(this.http.put(url, body, options));
  }
  delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
    options = this.addDefaultOptions(options);
    return this.http.delete(url, options);
  }

  responseToInstance<T>(response: Response, ctor: Constructor<T>): T {
    const data = response.json();
    return Object.assign(new ctor(), data);
  }

  responseToArray<T>(response: Response, ctor: Constructor<T>): T[] {
    const data = response.json();
    return data.map((elem) => Object.assign(new ctor(), elem));
  }
}
