import { Component, HostListener, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { TasksInformations } from 'app/components/pages/control-details/control-details.component';
import { Device } from 'app/models/device';
import { DocumentView } from 'app/models/document-viewer';
import { TaskParameter } from 'app/models/taskParameter';
import { LocalStorageUtils } from 'app/services/localstorage.utils';
import { TaskService } from 'app/services/task.service';
import { UiService } from 'app/services/ui.service';
import { PopupErrorComponent } from '../popup-error/popup-error.component';
import { PopupNotesComponent } from '../popup-notes/popup-notes.component';

export interface TaskParams {
  taskParameterId: number,
  value: string
}

@Component({
  selector: 'task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit {

  @ViewChild("task_view") task_view: TemplateRef<any>;
  @ViewChild("value_view") value_view: TemplateRef<any>;
  currentTemplate: TemplateRef<any>;

  hideButtonRight: boolean;
  hideButtonLeft: boolean;

  availableData: boolean;
  loading: boolean;

  task_full: boolean;
  pdf_full: boolean;

  task_width: number;
  pdf_width: number;

  zoomLevel = 1;
  zoomScaleFactor = 0.1;

  limit_view = false;

  @Input() taskContainer: TasksInformations;

  device: Device;
  devId: number;

  dialogRef: MatDialogRef<PopupErrorComponent>;
  noteRef: MatDialogRef<PopupNotesComponent>;


  constructor(
    public dialog: MatDialog,
    private _taskService: TaskService,
    private _router: Router,
    private _uiService: UiService,
    private _localStorageUtils: LocalStorageUtils
  ) { 
    this.devId = this._localStorageUtils.deviceId;
  }

  ngOnInit() {

    this.hideButtonLeft = true;
    this.hideButtonRight = true;

    this.task_full = false;
    this.pdf_full = false;

    this.task_width = 50;
    this.pdf_width = 50;

    this.currentTemplate = this.task_view;
  }

  //Buttons configuration

  expand() {
    this.hideButtonLeft = false;
    this.pdf_full = true;
    this.pdf_width = 100;
  }

  hide() {
    this.hideButtonRight = false;
    this.task_full = true;
    this.task_width = 100;
  }

  show_instruction() {
    this.hideButtonRight = true;
    this.task_full = false;
    this.task_width = 50;
  }

  show_detail_page() {
    this.hideButtonLeft = true;
    this.pdf_full = false;
    this.pdf_width = 50;
  }

  //PDF function

  async buildPdf (filename, taskId) {
    let buff = null;
    this.taskContainer.availableData = false;
    try {
        this.loading = true;

        buff = await this._taskService.getPDFTasks(taskId);
        if (buff && buff._body) {
            let file = new Blob([buff._body], { type: 'application/blob' });
            var fileURL = URL.createObjectURL(file);
            this.taskContainer.documentView = new DocumentView(true, fileURL);
            this.taskContainer.availableData = true;
        }

        this.loading = false;
    } catch (err) {
        console.log("Error : retriving file "+ filename);
        this.loading = false;
        this._uiService.openErrorSnackBar("Error : retriving file " + filename, "Ok")
    }
}

  zoomin() {
    this.zoomLevel = parseFloat((this.zoomLevel + this.zoomScaleFactor).toFixed(1));
  }

  zoomout() {
    if (this.zoomLevel > 0) {
      this.zoomLevel = parseFloat((this.zoomLevel - this.zoomScaleFactor).toFixed(1));
    }
  }

  resetZoom() {
    this.zoomLevel = 1;
  }

  //Params functions

  createParams(item: TaskParameter[]) {

    let params: TaskParams[] = []

    if (item.length > 0) {
      item.forEach(taskPar => {
        let param: TaskParams = {
          taskParameterId: taskPar.id,
          value: taskPar.value == null ? null : taskPar.value.toString()
        }
        params.push(param)
      });
    }

    return params
  }

  async nextTask() {
    let isEndPage = this.taskContainer.taskPage +1 == this.taskContainer.taskList.length;

    this.loading = true;
    this.taskContainer.documentView = null;

    if(!isEndPage){
      if(this.taskContainer.taskList[this.taskContainer.taskPage + 1].docLink){
        this.buildPdf(this.taskContainer.taskList[this.taskContainer.taskPage + 1].docLink, this.taskContainer.taskList[this.taskContainer.taskPage + 1].id)
        }
    }
    
    if(this.taskContainer.taskParamList[this.taskContainer.taskPage].length > 0){
    await this._taskService.updateTasks(this.taskContainer.taskHistoryList[this.taskContainer.taskPage].id, this.devId, this.taskContainer, this.taskContainer.taskPage, this.createParams(this.taskContainer.taskParamList[this.taskContainer.taskPage]))
    }
    else{
      await this._taskService.updateTasks(this.taskContainer.taskHistoryList[this.taskContainer.taskPage].id, this.devId, this.taskContainer, this.taskContainer.taskPage)
    }
    this.taskContainer.taskPage++
    if(!isEndPage){
    this.check_limit_value_done()
    }
    this.loading = false;
    if (isEndPage) {
      this.redirect()
    }
  }

  async previousTask() {
    this.taskContainer.taskPage--;
    this.loading = true;
    this.check_limit_value_done()
    if(this.taskContainer.taskList[this.taskContainer.taskPage].docLink){
      await this.buildPdf(this.taskContainer.taskList[this.taskContainer.taskPage].docLink, this.taskContainer.taskList[this.taskContainer.taskPage].id)
      }
      this.loading = false;
  }

  //Value check functions

  switch(template) {
    this.currentTemplate = template
    this.check_limit_value_done()
  }

  check_limit(params) {
    let check = false;
    if (params.value) {
      if (params.minThreshold != null && params.value < params.minThreshold) {
        check = true;
      }
      if (params.maxThreshold != null && params.value > params.maxThreshold) {
        check = true;
      }
      if (check) {
        this.openErrorDialog(params.outOfRangeDescription)
      }
    }
  }

  check_limit_value_done() {

    this.taskContainer.valueCounter = 0;
    if(this.taskContainer.taskParamList[this.taskContainer.taskPage].length > 0){
    this.taskContainer.taskParamList[this.taskContainer.taskPage].forEach(tmp => {
          if (!(this.taskContainer.valueCounter > this.taskContainer.taskParamList.length)) {
            tmp.value ? this.taskContainer.valueCounter++ : this.taskContainer.valueCounter
          }
    });
  }else{
    this.taskContainer.taskHistoryList[this.taskContainer.taskPage].value && this.taskContainer.taskHistoryList[this.taskContainer.taskPage].value != " " ? this.taskContainer.valueCounter = 1 : this.taskContainer.valueCounter = 0;
  }
}

  redirect() {
    this._router.navigateByUrl("qualitycontrol");
  }

  openDialog() {
    this.noteRef = this.dialog.open(PopupNotesComponent, { panelClass: 'custom-dialog-container', disableClose: true });
    this.noteRef.componentInstance.text = this.taskContainer.taskHistoryList[this.taskContainer.taskPage].note;
    this.noteRef.beforeClosed().subscribe(() => {
      this.taskContainer.taskHistoryList[this.taskContainer.taskPage].note = this.noteRef.componentInstance.text;
    })
  }

  openErrorDialog(errorMsg) {
    this.dialogRef = this.dialog.open(PopupErrorComponent, { panelClass: 'custom-dialog-container', disableClose: true });
    this.dialogRef.componentInstance.error = errorMsg
  }

  @HostListener('window:keyup.enter') onKeyUp() {
    this.dialogRef.close();
  }

}
