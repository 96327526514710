import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-popup-notes',
  templateUrl: './popup-notes.component.html',
  styleUrls: ['./popup-notes.component.scss']
})
export class PopupNotesComponent implements OnInit {

  text:string;

  constructor( private dialogRef: MatDialogRef<PopupNotesComponent>){

  }
  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
