import {TranslateService} from '@ngx-translate/core';
import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Device} from "../../../models/device";
import {MqttService} from "../../../services/mqtt.service";

@Component({
    selector: 'progress-bar',
    templateUrl: 'progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgressBarComponent implements OnInit, OnDestroy {
    @Input() device: Device;

    @Input() pieces: number = 0;
    @Input() clock: number = -1;
    @Input() percentage?: number = undefined;
    @Input() targetPieces?: number = undefined;

    @Input() startProduction;

    constructor(
        private _translate: TranslateService,
        private _mqttService: MqttService
    ) {
    }

    async ngOnInit() {
    }

    async ngOnDestroy() {
    }
}
