import {TranslateService} from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
/**
 * Date pipe wrapped with locale support from translate service
 *
 * @export
 * @class DateTimeFormatPipe
 * @extends {DatePipe}
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) {
    super(translate.currentLang);
  }
  transform(value: any, args?: any): any {
    return super.transform(value, args);
  }
}
