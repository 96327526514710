import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { TaskHistory } from 'app/models/taskHistory';
import { TasksInformations } from 'app/components/pages/control-details/control-details.component';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class TaskService {
    Tasks: TaskHistory[];
    constructor(
        private httpService: HttpService,
        private _envSetting: EnvConfigurationService) { }

    /**
     * Method to get all task
     *
     */
    async getTasks(controlHistoryId: number, deviceId: number) {
        let url = `${this._envSetting.settings.apiBase}/taskHistory/` + controlHistoryId + '?deviceId=' + deviceId;

        try {
            const response = await this.httpService.get(url)
            .toPromise()

            return response.json() as TaskHistory[]
            
        } catch (error) {
            return [] as TaskHistory[]
        }
    }

    async updateTasks(taskHistoryId: number, deviceId: number, task: TasksInformations, page:number, parameters?) {
       let req_body = parameters != null ?                 {
            "deviceId": deviceId,
            "note": task.taskHistoryList[page].note == null ? " " : task.taskHistoryList[page].note,
            "compliant": task.taskHistoryList[page].compliant,
            "operatorId": task.userId,
            "notDone": task.taskHistoryList[page].notDone,
            "parameters": parameters
        } : {
            "deviceId": deviceId,
            "note": task.taskHistoryList[page].note == null ? " " : task.taskHistoryList[page].note,
            "value": task.taskHistoryList[page].value == null ? " " : task.taskHistoryList[page].value,
            "compliant": task.taskHistoryList[page].compliant,
            "operatorId": task.userId,
            "notDone": task.taskHistoryList[page].notDone
                }

        let url = `${this._envSetting.settings.apiBase}/taskHistory/` + taskHistoryId;

        try {
          const response = await this.httpService.put(url,
                req_body)
                    .toPromise()
        } catch (error) {
            console.log(error)
        }
    }

    async getPDFTasks(taskId: number) {
            let url = `${this._envSetting.settings.apiBase}/tasks/docs/` + taskId;
            const response = await this.httpService.get(url, { responseType: ResponseContentType.Blob })
                    .toPromise()

                return response;
    }
}
