import { Device } from '../models/device';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Order } from '../models/order';

@Injectable()
export class OrderService {
    constructor(private httpService: HttpService) { }

    /**
     * Method to get order from satellite by single session
     *
     * @memberof OrderService
     * @param {number} sessionId
     * @returns {Order}
     */
    async getOrderFromSatellite(device: Device): Promise<Order> {
        const url = `${device.Company.satelliteUrl}/order/device/${device.id}`;
        try {
            const response = await this.httpService
                .get(url)
                .toPromise();

            return response.json() as Order;
        } catch (err) {
            return null;
        }
    }
}