import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MqttService} from '../../../services/mqtt.service';
import {DevicesService} from 'app/services/devices.service';
import {Device} from "../../../models/device";
import {ActivatedRoute} from "@angular/router";
import {Timer} from "../../ui/device-box/Timer";
import * as moment from "moment";
import {OeeParams} from "../../../models/stats";
import {StatsService} from "../../../services/stats.service";
import {NavbarService} from "../../../services/navbar.service";
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';
import { Stop } from 'app/models/stop';
import { StopService } from 'app/services/stop-history.service';
import { MqttUtils } from 'app/services/mqtt.utils';
import { SnackbarType } from 'app/models/snackbar';
import { SnackbarService } from 'app/services/snackbar.service';

@Component({
    templateUrl: './device-details.component.html',
    styleUrls: ['./device-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceDetailsComponent extends PageWithLoader implements OnInit, OnDestroy {
    device: Device = null;
    loading = false;

    oee: OeeParams;
    oeeTimer: Timer;
    stop: Stop;

    idDevice: number;

    constructor(
        private _deviceService: DevicesService,
        private _mqttService: MqttService,
        private _activeRoute: ActivatedRoute,
        private _statsService: StatsService,
        public navbar: NavbarService,
        private _stopService: StopService,
        private _mqttUtils: MqttUtils,
        private _snackbarService: SnackbarService,
        _themeService: ThemeService
    ) {
        super(_themeService);
        const thirtyMinutes = 30 * 60 * 1000;
        this.oeeTimer = new Timer({
            onStart: async () => {
                this.oee = await this._statsService.getAggregateOee(this.device.getBegin(), moment().toISOString(), [this.device.id]);
                this.oee.oee *= 100;
            },
            onStop: () => {
                this.oee = undefined
            },
            timeoutMs: thirtyMinutes
        });
    }

    async ngOnInit() {
        this.loading = true;
        this._activeRoute.params.subscribe(async (params) => {
            this.idDevice = params["id"];

            let result = await this._mqttService.connectWebUser();            
            if (!result) {
                this._snackbarService.showSnackbar('Error retrieving devices status.\nPlease try again later.', SnackbarType.error, { verticalPosition: 'bottom'});
            }
            await this.subscribeToDataChannel(1);
            await this.updateDeviceStatus();
            this.navbar.setTitle(this.device.label);

            await this.waitForChangeStatus();

            this.loading = false;
        });
    }

    async subscribeToDataChannel(retry: number) {
        if(retry >= 10) {
            console.log("You have reached maximum number of retry without success");
            return;
        }
        if(!this._mqttService.isConnected()) {
            await this.sleep(2000);
            await this.subscribeToDataChannel(retry + 1);
        } else {
            console.log(`subscribe to ${this._mqttUtils.computeTopicForDevice(this.idDevice)}`);
            await this._mqttService.subscribe(this._mqttUtils.computeTopicForDevice(this.idDevice));
        }
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async ngOnDestroy() {
        console.log(`unsubscribe to ${this._mqttUtils.computeTopicForDevice(this.idDevice)}`);
        await this._mqttService.unsubscribe(this._mqttUtils.computeTopicForDevice(this.idDevice));
    }

    async updateDeviceStatus() {
        this.device = await this._deviceService.getDevice(this.idDevice);
        this.oeeTimer.reset(this.device.isProducing() || this.device.isStopped());
        if (this.device.isStopped()) {
            this.stop = await this._stopService.getActive(this.device.id);
        } else {
            this.stop = null;
        }
    }

    async waitForChangeStatus() {
        if(this._mqttService.client) {
            this._mqttService.client.on('message', async (topic: string, payload: Buffer) => {
                const command = parseInt(topic.substr(0, 2), 10);
                const deviceId = this._mqttUtils.getDeviceIdFromTopic(topic);
                if (command === 10 && deviceId === this.device.id) {
                    const commandType = payload.readUIntBE(0, 1);
                    if (commandType === 40 && deviceId === this.device.id) {
                        await this.updateDeviceStatus();
                    }
                }
            });
        }
    }
}
