import { Injectable } from '@angular/core';
import { LocalStorageUtils } from './localstorage.utils';

@Injectable()
export class UserUtils {
    constructor(private _localStorageUtils: LocalStorageUtils) { }   

    /**
     * Return true is loggedin user is admin
     * 
     * @memberof UserUtils
     */
     isAdmin() {
         return this._localStorageUtils.role == 0;
    }
}