import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UiService } from '../../../services/ui.service';
import { ActiveFiltersService } from '../../../services/active-filters.service';
import {MqttService} from "../../../services/mqtt.service";
import { ThemeService } from 'app/services/theme.service';
import { PageWithLoader } from '../page-with-loader';
import { LocalStorageUtils } from 'app/services/localstorage.utils';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})

export class LoginComponent extends PageWithLoader implements OnInit {
    // root page defined here (instead of app.ts) to avoid cyclic imports
    public static rootPagePath = 'realtime';

    wait: boolean;

    user: any = {};

    res = false;

    feedback: string;

    public mail: string;

    public password: string;

    public recover_mail: string;

    public loading = false;

    passwordLost = false;

    loginForm = new FormGroup({
        mail: new FormControl('', [
            Validators.required
        ]),

        password: new FormControl('', [
            Validators.required
        ])
    });

    resetForm = new FormGroup({
        recover_mail: new FormControl('', [
            Validators.required
        ])
    });

    constructor(
        private _loginService: LoginService,
        private _ui: UiService,
        private _router: Router,
        private _translate: TranslateService,
        private _mqttService: MqttService,
        private _localStorageUtils: LocalStorageUtils,
        _themeService: ThemeService
    ) {
        super(_themeService);
    }


    async login() {
        this.loading = true;
        this.wait = true;
        
        this.res = await this._loginService.login(this.mail, this.password);
        
        this.wait = false;
        this.loading = false;

        if (this.res) {
            try {
                await this._mqttService.connect(this._localStorageUtils.readFromLocalStorage('user'), this._localStorageUtils.readFromLocalStorage('token'));
            } catch (err) {
                console.error(err);
            }

            // user authenticated
            this.feedback = this._translate.instant('login.logged_in');
            this._ui.openSnackBar(this.feedback);

            this._router.navigateByUrl(LoginComponent.rootPagePath);
        } else {
            // auth error
            this.feedback = this._translate.instant('login.wrong');
            this._ui.openSnackBar(this.feedback);
            this._themeService.setTeamSystemTheme(window.location.href.includes("mes-industry40.teamsystem.com"));
        }
    }

    public async resetSubmit(): Promise<any> {
        if (!this.resetForm.valid) return;

        try {
            this.loading = true;
            const lang = this._localStorageUtils.lang || 'en';
            const res = await this._loginService.resetPassword(this.recover_mail, lang);

            if (res) {
                this._translate.get('login.recover.success').subscribe((res) => {
                    this._ui.openSnackBar(res);
                });
            }

        } catch (err) {
            this._translate.get('login.recover.wrong').subscribe((res) => {
                this._ui.openSnackBar(res);
            });
        }

        this.loading = false;

    }

    async ngOnInit() {
        if (this._mqttService.isConnected()) {
            await this._mqttService.disconnect();
        }
    }

    isPasswordLost(is: boolean) {
        this.passwordLost = is ? true : false;
    }
}
