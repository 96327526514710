import { Device } from '../models/device';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { Order } from 'app/models/order';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class DevicesService {
    devices: Device[];
    constructor(
        private httpService: HttpService,
        private _envSetting: EnvConfigurationService) { }

    /**
     * Method to get all devices
     *
     * @memberof DeviceService
     */
    async getDevices() {
        const url = `${this._envSetting.settings.apiBase}/device?populateCompany=true`;
        const response = await this.httpService
            .get(url)
            .toPromise();
        this.devices = this.httpService.responseToArray(response, Device);

        // sort alphanumeric label
        this.devices.sort((a, b) => {
            var nameA = a.label.toUpperCase(); // ignore upper and lowercase
            var nameB = b.label.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0; // names must be equal
        });

        // sort companyId
        this.devices.sort((a, b) => {
            return a.companyId - b.companyId;
        })

        return this.devices;
    }

    /**
     * Method to get a device
     *
     * @memberof DeviceService
     */
    async getDevice(deviceId: number) {
        const url = `${this._envSetting.settings.apiBase}/device/${deviceId}?includePlugins=true`;
        const response = await this.httpService
            .get(url)
            .toPromise();
        return this.httpService.responseToInstance(response, Device);
    }

    /**
     * Method to get the device from the list with the given id
     *
     * @memberof DeviceService
     * @param {number} deviceId
     * @returns {Device}
     */
    getDeviceInfo(deviceId: number): Device {
        for (const device of this.devices) {
            if (device.id === deviceId) {
                return device;
            }
        }
        return null;
    }

    /**
     * Method to get plugin order on Satellite from single deviceId
     *
     * @memberof DeviceService
     * @param {number} deviceId
     * @returns {boolean}
     */
    async getDevicePluginOrderOnSatellite(device: Device): Promise<boolean> {
        if (device && device.Plugins) {
            for (const plugins of device.Plugins) {
                if (plugins.code === 'order') {
                    if (plugins.PluginsDevices
                        && plugins.PluginsDevices.enabled
                        && plugins.PluginsDevices.isOnSatellite) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**  
     * Method to get formatter data 
     * 
     * @memberof DeviceService
     * @param {Device} device
     * @param {Order} order
     * @returns {}
    */
    getDeviceDataFormatted(device: Device, orderFromSatellite?: Order): {
        pieces: number, targetPieces: number, targetSpeed: number, order: string, productCode: string,
        startProduction: any, downTime: number, stopCount: number
    } {
        const { Order, OrderDevice, Session, Product, stopCounter } = device;
        const pieces = (orderFromSatellite ? orderFromSatellite.items ? orderFromSatellite.items : 0 : OrderDevice ? OrderDevice.items : 0) + (Session ? Session.itemsTotal : 0);
        const targetPieces = orderFromSatellite ? orderFromSatellite.target ? orderFromSatellite.target : null : Order ? Order.target : null;
        const targetSpeed = Product ? Product.idealPiecesPerMinutesSpeed * 60 : null;
        const order = orderFromSatellite ? orderFromSatellite.code ? orderFromSatellite.code : undefined : Order ? Order.code : undefined;
        const productCode = Product ? Product.code + ' - ' + Product.name : undefined;
        const startProduction = Session ? Session.beginAt : null;
        const downTime = device.downtimeWithPlanned;
        const stopCount = stopCounter ? stopCounter.notSpecified + stopCounter.planned + stopCounter.unplanned : 0;

        return { pieces, targetPieces, targetSpeed, order, productCode, startProduction, downTime, stopCount }
    }

}