import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '../../../services/navbar.service';
import { LoginService } from '../../../services/login.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {Event, NavigationStart, Router} from '@angular/router';
import {LoginComponent} from "../../pages/login/login.component";
import * as moment from "moment";
import { Location } from '@angular/common';
import { Observable } from 'rxjs/Rx';
import { ThemeService } from 'app/services/theme.service';
import { LocalStorageUtils } from 'app/services/localstorage.utils';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NavBarComponent implements OnInit{
    enableDocumentation: boolean = false;
    enableQualityControl: boolean = false;

    languages = [
        { code: 'en', locale: 'en-US', label: 'English' },
        { code: 'it', locale: 'it-IT', label: 'Italiano' },
        { code: 'de', locale: 'de-DE', label: 'Deutsch' },
        { code: 'fr', locale: 'fr-FR', label: 'Français' },
        { code: 'el', locale: 'el-GR', label: 'Ελληνικά' }
    ];

    currentLang: string = null;

    showMenu = false;
    openMenu = false;
    childPage = false;
    devicePage = false;

    date: string = null;
    time: string = null;
    timeListener: Observable<string>;

    constructor(
        public translate: TranslateService,
        public loginService: LoginService,
        public navbar: NavbarService,
        private _router: Router,
        private location: Location,
        private _localStorageUtils: LocalStorageUtils,
        private _themeService: ThemeService
    ) {
        this.enableDocumentation = this._localStorageUtils.enableDocumentation;
        this.enableQualityControl = this._localStorageUtils.enableQualityControl;

        this.currentLang = this._localStorageUtils.lang || 'en';
        this.date = moment().locale(this.currentLang).format('L');
        this.time = moment().locale(this.currentLang).format("LT");
       
        this._router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.date = moment().locale(this.currentLang).format('L');
                this.time = moment().locale(this.currentLang).format("LT");
                const url : string = event.url || LoginComponent.rootPagePath;
                this.childPage = this.isChildPage(url);

                // using the url path to map localized title for the navbar
                let key = url.replace("/", "") + ".title";
                if (this.childPage) {
                    let path = url.split("/").slice(1);
                    key = `${path[0]}_${path[1]}.title`;
                }
                this.translate.get(key).subscribe((res: string) => {
                    this.navbar.setTitle(res);
                });
            }
        });
    }
    ngOnInit(): void {
        Observable.interval(1000*60).subscribe(() => {
              this.time = moment().locale(this.currentLang).format("LT") // you need the value of now not the value of the initialized time.
            })
    }

    goBack() {
        this.location.back();
    }

    async logout() {
        await this.loginService.logout();
    }

    toggleMenu() {
        this.showMenu = !this.showMenu;
    }
    /**
     * Check if the current page is the home page
     *
     * @memberof FilterBarComponent
     */
    public isRootPage(): boolean {
        return this._router.isActive(LoginComponent.rootPagePath, true);
    }

    public isChildPage(url): boolean {
        return url.split("/").length > 2;
    }

    public isLoginPage(): boolean {
        return this._router.isActive('/login', true);
    }

    hasToken(): boolean {
        const token = this._localStorageUtils.token;

        return token != undefined && token != null;
    }

    setLanguage(locale: string) {
        if (this.translate.currentLang === locale) {
            return;
        }

        this._localStorageUtils.lang = locale;
        window.location.reload();
    }

    /**
     * Function to navigate to another view
     *
     * @param {any} path
     * @memberof NavBarComponent
     */
    navigate(path) {
        this._router.navigateByUrl(path);
        this.showMenu = false;
    }

    getDirImages() {
        // console.log(this._themeService.isTeamSystemThemeSetted());
        return this._themeService.isTeamSystemThemeSetted() ? 'team_system/' : '';
    }

}
