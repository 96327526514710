import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}



fetch('/configuration/smarttv-config.json')
  .then((response) => response.json())
  .then((config) => {
    if (config.production) {
      enableProdMode()
    }

    platformBrowserDynamic().bootstrapModule(AppModule);
  })
  .catch((err) => {
    console.error(err);
    platformBrowserDynamic().bootstrapModule(AppModule);
  })
