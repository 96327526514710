import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MqttService } from '../../../services/mqtt.service';
import { Device } from '../../../models/device';
import { DocumentView, DocumentCategory, DocumentViewType } from '../../../models/document-viewer';
import { DevicesService } from 'app/services/devices.service';
import { ActiveFiltersService, DropdownItem } from 'app/services/active-filters.service';
import { ProductService } from 'app/services/product.service';
import { PageWithLoader } from '../page-with-loader';
import { ThemeService } from 'app/services/theme.service';
import { LocalStorageUtils } from 'app/services/localstorage.utils';
import { MqttCommand, MqttDeviceStateEvent, MqttUtils } from 'app/services/mqtt.utils';
import { SnackbarType } from 'app/models/snackbar';
import { SnackbarService } from 'app/services/snackbar.service';
import { Product } from 'app/models/product';

@Component({
    templateUrl: './document-view.component.html',
    styleUrls: ['./document-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentViewComponent extends PageWithLoader implements OnInit, OnDestroy {
    private _filterSubscription;
    loading = true;
    currentProductId = null;

    // Implementation for x numbers of PDF (now we use 2 or 1)
    // Position 0 is for the newest document
    // Array of documentView

    nPdfView: DocumentViewType;

    doublePdfView: boolean = false;

    device: Device;
    
    documentView: DocumentView[] = [];
    categories: number[] = []
    availableData: boolean[] = []

    zoomLevel: number[] = [];
    zoomScaleFactor = 0.1;

    // documentViewOld: DocumentView = null;
    // documentViewNew: DocumentView = null;

    // categoryOld = null;
    // categoryNew = null;
    
    categoryEnum = DocumentCategory;
    // availableDataOld = false;
    // availableDataNew = false;

    // zoomLevelOld = 1;
    // zoomScaleFactorOld = 0.1;

    // zoomLevelNew = 1;
    // zoomScaleFactorNew = 0.1;

    constructor(
        private _deviceService: DevicesService,
        private _mqttService: MqttService,
        private _activeFilterService: ActiveFiltersService,
        private _productService: ProductService,
        private _snackbarService: SnackbarService,
        private _localStorageUtils: LocalStorageUtils,
        private _mqttUtils: MqttUtils,
        _themeService: ThemeService
    ) {
        super(_themeService);
    }

    async ngOnInit() {
        await this._mqttService.connectWebUser();

        // subscribe to device changed event
        this._filterSubscription = this._activeFilterService.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });
        await this._activeFilterService.initializeActiveDevice(true);
        if (this._activeFilterService.productId === 0 && this._activeFilterService.productsList.filter(el => el.id !== 0).length > 0) { // IF IS SELECTED 'ALL PRODUCTS' THEN SELECT THE FIRST PRODUCT
            // const productId = this._activeFilterService.productsList?.length ?
            const productId = this._activeFilterService.productsList.filter(el => el.id !== 0)[0].id || null;
            this._activeFilterService.productId =  productId;
        }
        await this.onFilterChanged();

        await this.waitData();

        // go to first tab
        this.getDocument(DocumentCategory.code_mold);
    }

    ngOnDestroy() {
        if (this._filterSubscription) {
            this._filterSubscription.unsubscribe();
        }
        this._mqttService.unsubscribe('10/' + this.device.id);
        this._mqttService.unsubscribe(this._mqttUtils.computeTopicEndpoint(this.device.id));
    }

    async connectToMqtt() {
        let connected = await this._mqttService.connectWebUser();

        if (!connected) {
            this._snackbarService.showSnackbar('Error retrieving devices status.\nPlease try again later.',SnackbarType.error, { verticalPosition: 'bottom'});
        }
    }

    /**
     * Listen message
     *
     * @memberof DocumentViewComponent
     */
    async waitData() {
        const handelMessage = async () => {
            this.device = await this._deviceService.getDevice(this.device.id);
            let newProductId = this._activeFilterService.productsList.find(prod => prod.id === this._activeFilterService.productId) ? this._activeFilterService.productsList.find(prod => prod.id === this._activeFilterService.productId).id : null;
            if (this.currentProductId !== newProductId) {
                this.currentProductId = this.device.Product.id;
                this.documentView[0] = null;
                this.documentView[1] = null;
                this.nPdfView = DocumentViewType.single_pdf;
                // this.documentViewOld = null;
                // this.documentViewNew = null;
                this.getDocument(DocumentCategory.code_mold);
            }
        }
        this.loading = false;
        this._mqttService.client.on('message', async (topic: string, payload: Buffer) => {
            if (topic.substring(0, topic.indexOf('/')) === MqttCommand.endpointEvents) {
                const jsonPayload = JSON.parse(payload.toString());
                if (topic.includes('production/end') || jsonPayload.event === MqttDeviceStateEvent.endAllSessions || jsonPayload.event === MqttDeviceStateEvent.startProduction) {
                    await handelMessage();
                }
            } else {
                const command = parseInt(topic.substr(0, 2), 10);
                const deviceId = this._mqttUtils.getDeviceIdFromTopic(topic);
                if (command === 10 && deviceId === this.device.id) {
                    const commandType = payload.readUIntBE(0, 1);
                    const eventType = payload.readUIntBE(1, 1);
                    if (commandType === 40 && (eventType === 5 || eventType === 10)) {
                        await handelMessage();
                    }
                }
            }
        });
    }

    /**
     * retrieve documents for device and selected product
     *
     * @memberof DocumentViewComponent
     */
    async getDocument(category) {
        const product = this._activeFilterService.productsList.find(prod => prod.id === this._activeFilterService.productId);

        if (category && product) {
            if (this.categories[0]){
                this.categories[1] = this.categories[0].valueOf();
                this.zoomLevel[1]= this.zoomLevel[0];
            }
            this.categories[0] = category;
            this.resetZoom(0);
            switch(this.categories[0]){
                case DocumentCategory.code_mold :
                    if (this.documentView[0] && this.availableData[0]) {
                        this.documentView[1] = Object.assign({}, this.documentView[0]);
                        this.availableData[1] = true;
                    };
                    this.documentView[0] = new DocumentView(false, product.label);
                    this.availableData[0] = true;
                    break;
                case DocumentCategory.control_and_production_plan :
                    await this.buildPdf("PPC.pdf");
                    break;
                case DocumentCategory.tooling_instructions :
                    await this.buildPdf("IA.pdf");
                    break;
                case DocumentCategory.control_instructions :
                    await this.buildPdf("IC.pdf");
                    break;
                case DocumentCategory.technical_design :
                    await this.buildPdf("DT.pdf");
                    break;
                case DocumentCategory.derogation :
                    await this.buildPdf("DER.pdf");
                    break;
            }
        }
    }

    /**
     * Detect change of device or product
     *
     * @memberof DocumentViewComponent
     */
    async onFilterChanged() {
        if (this.device) {
            console.log(`unsubscribe to ${this._mqttUtils.computeTopicForDevice(this.device.id)}`);
            console.log(`unsubscribe to ${this._mqttUtils.computeTopicEndpoint(this.device.id)}`);
            await this._mqttService.unsubscribe(this._mqttUtils.computeTopicForDevice(this.device.id));
            await this._mqttService.unsubscribe(this._mqttUtils.computeTopicEndpoint(this.device.id));
        }
        const devId = this._localStorageUtils.deviceId;
        this.device = await this._deviceService.getDevice(devId);
        
        console.log(`subscribe to ${this._mqttUtils.computeTopicForDevice(this.device.id)}`);
        console.log(`subscribe to ${this._mqttUtils.computeTopicEndpoint(this.device.id)}`);
        await this._mqttService.subscribe(this._mqttUtils.computeTopicForDevice(this.device.id));
        await this._mqttService.subscribe(this._mqttUtils.computeTopicEndpoint(this.device.id));

        this.currentProductId = this.device.Product ? this.device.Product.id : null;
        this.doublePdfView = false;

        this.resetZoom(0);
        this.resetZoom(1);

        this.documentView = [];
        this.categories = [];
        this.availableData = [];

        this.getDocument(DocumentCategory.code_mold);

        // Dobbiamo ricaricare i documenti??

    }

    /**
     *  build pdf for rendering
     *
     * @param {*} filename
     * @memberof DocumentViewComponent
     */
    async buildPdf (filename) {
        const getProductDocs = async (productCode: DropdownItem) => {
            buff = await this._productService.getProductDocs(this.device.companyId, this.device.plantId, productCode.code, filename);
            if (buff && buff._body) {
                let file = new Blob([buff._body], { type: 'application/blob' });
                var fileURL = URL.createObjectURL(file);
                if (this.documentView[0] && this.availableData[0]) {
                    this.documentView[1] = Object.assign({}, this.documentView[0]);
                    this.availableData[1] = true;
                };
                this.documentView[0] = new DocumentView(true, fileURL);
                this.availableData[0] = true;
            }
        }
        let buff = null;
        try {
            this.loading = true;
            let productCode: DropdownItem;
            if (this._activeFilterService.productId === 0) {
                // ALL PRODUCT
                
            } else {
                productCode = this._activeFilterService.productsList.find(product => product.id === this._activeFilterService.productId);
                await getProductDocs(productCode);
            }

            this.loading = false;
        } catch (err) {
            console.log("Error : retriving file "+ filename);
            if (this.availableData[0]) {
                this.documentView[1] = Object.assign({}, this.documentView[0]);
                this.availableData[1] = true;
            }
            this.loading = false;
            this.availableData[0] = false;
            this.categories[0] = null;
        }
    }

    zoomin(index: number) {
        this.zoomLevel[index] = parseFloat((this.zoomLevel[index] + this.zoomScaleFactor).toFixed(1));
    }

    zoomout(index: number) {
        if (this.zoomLevel[index] > 0) {
            this.zoomLevel[index] = parseFloat((this.zoomLevel[index] - this.zoomScaleFactor).toFixed(1));
        }
    }

    resetZoom(index: number) {
        this.zoomLevel[index] = 1;
    }
}
