import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
    @Input() message: string;
    @ViewChild('messageObject') messageObj;

    style = {
        top: '0px',
        left: '0px',
        visibility: 'hidden'
    };

    width = 0;
    height = 0;

    constructor(
        private _el: ElementRef
    ) { }

    ngOnInit() { }

    show(e, msg?) {
        if(msg)
            this.message = msg;

        this.width = this.messageObj.nativeElement.offsetWidth;
        this.height = this.messageObj.nativeElement.offsetHeight;

        this.style.visibility = 'visible';
        this.style.top = (e.clientY - 1 - this.height) + 'px';

        if (e.clientX + this.width - window.innerWidth > 0) {
            this.style.left = (e.clientX - 1 - this.width) + 'px';
        } else {
            this.style.left = (e.clientX + 1) + 'px';
        }
    }

    hide(e) {
        this.style.visibility = 'hidden';
    }
}
