import * as moment from 'moment/moment';
import { Error } from 'tslint/lib/error';
import { Product } from '../models/product';
import { ProductService } from './product.service';
import { Subject } from 'rxjs/Rx';
import { DevicesService } from './devices.service';
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '../models/device';
import { Control } from 'app/models/control';
import { ControlService } from './control.service';
import { ControlHistory } from 'app/models/controlHistory';
import { LocalStorageUtils } from './localstorage.utils';
import { SessionService } from './session.service';
import { Session } from 'app/models/session';

export interface DropdownItem {
    id: number;
    label: string;
    code: string;
}

export interface OrderDropdownItem {
    id: string;
    label: string;
}

export type DateInterval = 'today' | 'yesterday' | 'lastweek' | 'week' | 'month' | 'lastmonth' | 'year' | 'schedules';

@Injectable()
export class ActiveFiltersService {
    private _deviceId: number;
    private devices: Device[];
    private _device: Device;
    private _dateBegin: Date;
    private _dateEnd: Date;
    public _productId = 0;
    public _controlId = 0;
    public _orderId = "";
    public _granularity = 0;
    public _timerMode = false;
    public productsList: DropdownItem[];
    public controlsList: ControlHistory[];
    public ordersList: OrderDropdownItem[];
    public activeDateInterval: DateInterval;
    public onFilterChanged = new EventEmitter<void>();
    public onDeviceFilterChanged: Subject<number> = new Subject<number>();
    constructor(
        private _devicesService: DevicesService,
        private _productService: ProductService,
        private _sessionService: SessionService,
        private _translate: TranslateService,
        private _localStorageUtils: LocalStorageUtils
    ) { }

    /**
     * Try to set an active device (the first) if available
     *
     *
     * @memberOf ActiveFiltersService
     */
    async initializeActiveDevice(isDocumentPage?: boolean) {
        // TODO: Recheck this when there will be a devices page
        this.devices = await this._devicesService.getDevices();
        if (!this.deviceId) {
            if (this.devices.length) {
                for (const device of this.devices) {
                    if (!device.ProductionLine) {
                        this.deviceId = this.devices[0].id;
                        break;
                    }
                }
            } else {
                // TODO: discover why Error is not a constructor
                // throw new Error('no devices found');
                console.error('no devices found');
            }
        }
        this._device = this.devices.filter((item) => {
            return item.id === this.deviceId;
        })[0];

        if (this._device) {
            this._timerMode = this._device.sessionType === 2 ? true : false;
        }
        this.emitChangeEvent(true, isDocumentPage);
    }

    /**
     * Emit an event change, to call when a filter is changed
     *
     *
     * @memberOf ActiveFiltersService
     */
    emitChangeEvent(preventEmit?: boolean, isDocumnetPage?: boolean) {
        this._localStorageUtils.deviceId = this._deviceId;
        this._localStorageUtils.productId = this._productId;
        this._localStorageUtils.granularity = this._granularity;
        this._localStorageUtils.writeInLocalStorage('dateBegin', moment(this._dateBegin).format());
        this._localStorageUtils.writeInLocalStorage('dateEnd', moment(this._dateEnd).format());
        if (!preventEmit) {
            this.onFilterChanged.emit();
            this._refetchProductsList(isDocumnetPage);
        }
    }

    /**
     *
     * Set begin and end date from an interval string (i.e. 'today')
     *
     * @param {DateInterval} intervalString
     *
     * @memberOf ActiveFiltersService
     */
    setDateFromIntervalString(intervalString: DateInterval) {
        [this.dateBegin, this.dateEnd] = this.computeDatesFromIntervalString(intervalString);
        this._localStorageUtils.writeInLocalStorage('dateBegin', moment(this._dateBegin).format());
        this._localStorageUtils.writeInLocalStorage('dateEnd', moment(this._dateEnd).format());
        this.activeDateInterval = intervalString;
    }

    /**
     *
     * Set begin and end date
     *
     * @param {Date} startDate
     * @param {Date} endDate
     *
     * @memberOf ActiveFiltersService
     */
    setDates(startDate: Date, endDate: Date) {
        this.dateBegin = startDate;
        this.dateEnd = endDate;
        this._localStorageUtils.writeInLocalStorage('dateBegin', moment(this._dateBegin).format());
        this._localStorageUtils.writeInLocalStorage('dateEnd', moment(this._dateEnd).format());
    }

    /**
     * Compute begin and end date from an interval string (i.e. 'today')
     *
     * @param {DateInterval} intervalString
     * @returns {Date}
     *
     * @memberOf ActiveFiltersService
     */
    computeDatesFromIntervalString(intervalString: DateInterval): Date[] {
        let begin = moment().toDate();
        let end = moment().toDate();
        begin.setHours(0);
        begin.setMinutes(0);
        begin.setSeconds(0);

        let dayOfWeek: number;
        switch (intervalString) {
            case 'today':
                break;
            case 'yesterday':
                begin.setDate(begin.getDate() - 1);
                end.setDate(end.getDate() - 1);
                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59);
                break;
            case 'lastweek':
                // js dates starts from sunday, shift the day in order to begin from monday
                dayOfWeek = begin.getDay() - 1;
                if (dayOfWeek < 0) {
                    dayOfWeek = 6;
                }

                // set the date to monday
                begin.setDate(begin.getDate() - dayOfWeek);
                begin.setHours(0);
                begin.setMinutes(0);
                begin.setSeconds(0);
                end = new Date(begin);
                end.setDate(end.getDate() - 1);
                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59);
                begin.setDate(begin.getDate() - 7);
                break;
            // case 'week':
            //     // js dates starts from sunday, shift the day in order to begin from monday
            //     let day = begin.getDay() - 1;
            //     if (day < 0) {
            //         day = 6;
            //     }

            //     // set the date to monday
            //     begin.setDate(begin.getDate() - day);
            //     break;
            case 'lastmonth':
                begin.setDate(-1);
                begin.setDate(1);
                end.setDate(1);
                end.setDate(end.getDate() - 1);
                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(59);
                break;
            case 'month':
                begin.setDate(1);
                begin.setHours(0);
                begin.setMinutes(0);
                begin.setSeconds(0);
                break;
            case 'year':
                begin.setMonth(0);
                begin.setDate(1);
                begin.setHours(0);
                begin.setMinutes(0);
                begin.setSeconds(0);
                break;
            case 'schedules':
                begin = end;
                break;
            default:
                throw new Error('wrong date interval');
        }

        return [begin, end];
    }

    get granularity() {
        return this._granularity;
    }
    set granularity(granularity: number) {
        this._granularity = granularity;
    }
    get deviceId() {
        return parseInt('' + this._deviceId, 10);
    }
    get timerMode() {
        return this._timerMode;
    }
    set deviceId(id: number) {
        const isDocumentPage =  window.location.pathname === '/documentview';
        this._deviceId = id;
        if (!isDocumentPage) {
            this._productId = 0;
        }
        this._orderId = '';
        // this._satelliteUrl = this.devices.filter((item) => {
        //     return item.id === this.deviceId
        // })[0].Company.satelliteUrl;

        // reload products list        
        this._refetchProductsList(isDocumentPage);

        // reload order list
        this.onDeviceFilterChanged.next(id);
    }

    get device() {
        if (this.devices) {
            return this._device = this.devices.filter((item) => {
                return item.id === this.deviceId;
            })[0];
        }
    }
    get productId() {
        return this._productId;
    }
    set productId(productId: any) {
        if (typeof productId === 'string') {
            this._productId = parseInt(productId, 10);
        } else if (typeof productId === 'number') {
            this._productId = productId;
        }
    }
    get orderId() {
        return this._orderId;
    }
    set orderId(orderId: any) {
        if (typeof orderId === 'string') {
            this._orderId = orderId;
        } else if (typeof orderId === 'number') {
            this._orderId = orderId.toString();
        }
    }

    get controlId() {
        return this._controlId;
    }
    set controlId(controlId: any) {

        if (typeof controlId === 'string') {
            this._controlId = parseInt(controlId, 10);
        } else {
            this._controlId = controlId;
        }
    }

    set dateBegin(date: Date) {
        this._dateBegin = date;
        this.activeDateInterval = null;
    }
    get dateBegin() {
        return this._dateBegin;
    }
    set dateEnd(date: Date) {
        this._dateEnd = date;
        this.activeDateInterval = null;
    }
    get dateEnd() {
        return this._dateEnd;
    }

    /**
     * Reload list of products from server and build the dropdown list
     *
     * @returns {Promise<any>}
     *
     * @memberOf ActiveFiltersService
     */
    async _refetchProductsList(isDocumnetPage?: boolean): Promise<any> {
        if (this.deviceId) {
            let products: Product[];
            if (this._dateBegin && this._dateEnd) {
                // products = await this._productService.getProducts(this.deviceId, this.dateBegin.toISOString(), this.dateEnd.toISOString());
                products = await this.computeProduct()
            } else if (this._localStorageUtils.readFromLocalStorage('dateBegin') && this._localStorageUtils.readFromLocalStorage('dateEnd')) {
                this.dateBegin = this._localStorageUtils.readFromLocalStorage('dateBegin') ? moment(this._localStorageUtils.readFromLocalStorage('dateBegin')).toDate() : null;
                this.dateEnd = this._localStorageUtils.readFromLocalStorage('dateEnd') ? moment(this._localStorageUtils.readFromLocalStorage('dateEnd')).toDate() : null;
                // products = await this._productService.getProducts(this.deviceId, this.dateBegin.toISOString(), this.dateEnd.toISOString());
                products = await this.computeProduct();
            } else {
                this.dateBegin = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)); // yesterday
                this.dateEnd = new Date();
                //products = await this._productService.getProducts(this.deviceId, this.dateBegin.toISOString(), this.dateEnd.toISOString());
                products = await this.computeProduct();
            }
            // if (products && products.length > 0) {
            //     this._rebuildProductsList(products);
            // }
            this._rebuildProductsList(products, isDocumnetPage);
        }
    }

    private async computeProduct(): Promise<Product[]> {
        try {
            let now = new Date();
            let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));

            const activeSessions: Session[] = await this._sessionService.getAll(yesterday.toISOString(), now.toISOString(), this.deviceId, null);
            if (activeSessions && activeSessions.length) {
                const products: Product[] = activeSessions
                  .filter(session => session.active && !session.endAt && session.productId)
                  .map(session => session.Product);
                const uniqueProdsTmp = Array.from(new Set(products.map(el => el.id)));
                let uniqueProds = [];
                uniqueProdsTmp.forEach((el) => {
                    uniqueProds.push(products.find(prod => prod.id === el));
                });

                return uniqueProds;
            } else {
                return [];
            }
        } catch(err) {

        }
    }

    /**
     * Rebuild products list with the default ('all products') plus the products given
     *
     * @private
     * @param {Product[]} [products]
     *
     * @memberOf ActiveFiltersService
     */
    private async _rebuildProductsList(products?: Product[], isDocumnetPage?: boolean) {
        let productsList: DropdownItem[] = [];
        if (!isDocumnetPage) {
            let label = 'ALL PRODUCTS';
            await this._translate.get('filter_bar.all_products').subscribe((res: string) => {
                label = res;
            });
            productsList = [{
                id: 0,
                label: label,
                code: ''
            }];
        }

        products.sort((a, b) => {
            var nameA = a.code.toUpperCase(); // ignore upper and lowercase
            var nameB = b.code.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0; // names must be equal
        });

        if (products && products.length > 0) {

            products.forEach(product => {
                if (product && product.id) {
                    productsList.push({
                        id: product.id,
                        label: product.code + " - " + product.name,
                        code: product.code
                    });
                }
            });
        }

        this.productsList = productsList;
        if (isDocumnetPage && productsList.length && this.productId === 0) {
            this.productId = productsList[0].id || null;
        }
    }

    /**
     * Rebuild products list with the default ('all products') plus the products given
     *
     * @private
     * @param {Product[]} [products]
     *
     * @memberOf ActiveFiltersService
     */
    private async _rebuildOrdersList(orders?: any[]) {
        let label = 'ALL ORDERS';
        await this._translate.get('filter_bar.all_orders').subscribe((res: string) => {
            label = res;
        });
        const ordersList: OrderDropdownItem[] = [{
            id: '',
            label: label
        }];

        orders.sort((a, b) => {
            var nameA = a.code.toUpperCase(); // ignore upper and lowercase
            var nameB = b.code.toUpperCase(); // ignore upper and lowercase

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0; // names must be equal
        });

        if (orders) {

            orders.forEach(order => {
                ordersList.push({
                    id: order.code,
                    label: order.code
                });
            });
        }

        this.ordersList = ordersList;
    }

    /**
     * Reload list of controls from server and build the dropdown list
     *
     * @returns {Promise<any>}
     *
     * @memberOf ActiveFiltersService
     */
    async _refetchControlsList(): Promise<any> {
        // if (this.deviceId) {
        //     const controls = await this._controlService.getControls(this.deviceId);
        //     if(controls){
        //         this.controlsList = controls;
        //     }
        // }
    }

    /**
     * Rebuild control list with the default ('all products') plus the products given
     *
     * @private
     * @param {Control[]} [control]
     *
     * @memberOf ActiveFiltersService
     */
    private async _rebuildControlsList(controls?: Control[]) {
        let label = 'ALL CONTROLS';
        // await this._translate.get('filter_bar.all_controls').subscribe((res: string) => {
        //     label = res;
        // });
        // const controlsList: DropdownItem[] = [{
        //     id: 0,
        //     label: label
        // }];

        // controls.sort((a, b) => {
        //     var nameA = a.description.toUpperCase(); // ignore upper and lowercase
        //     var nameB = b.description.toUpperCase(); // ignore upper and lowercase

        //     if (nameA < nameB) return -1;
        //     if (nameA > nameB) return 1;

        //     return 0; // names must be equal
        // });

        if (controls) {

            // controls.forEach(product => {
            //     controlsList.push({
            //         id: product.id,
            //         label: product.description
            //     });
            // });
        }

        // this.controlsList = controlsList;
    }
}