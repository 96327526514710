import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { Device } from "../../../models/device";
import { Timer } from "../device-box/Timer";
import * as moment from "moment/moment";
import { Stop } from "../../../models/stop";
import { StopService } from "../../../services/stop-history.service";
import { OeeParams } from "../../../models/stats";
import { Observable, observable } from 'rxjs';
import { StatsService } from "../../../services/stats.service";
import { ProductDetailBarComponent } from "../product-detail-bar/product-detail-bar.component";
import { ProgressBarComponent } from "../progress-bar/progress-bar.component";
import { OrderService } from '../../../services/order.service';
import { Order } from '../../../models/order';
import { DevicesService } from 'app/services/devices.service';

@Component({
    selector: 'device-stopped',
    templateUrl: 'device-stopped.component.html',
    styleUrls: ['./device-stopped.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceStoppedComponent implements OnInit, OnDestroy {
    @Input() device: Device;
    @Input() oee: OeeParams;
    @Input() stop: Stop;
    @ViewChild('productDetailBar') productDetailBar: ProductDetailBarComponent;

    defaultPercentage = 70;

    order: string = undefined;
    productCode: string = undefined;

    pieces: number = 0;
    percentage?: number = undefined;
    targetPieces?: number = undefined;
    clock: number;
    stopClock: number;
    stopTimer: Timer;
    stopCount: number;
    loading: boolean = true;

    startProduction;

    targetView: boolean = false;
    //sessionEmpty: boolean = false;
    stopTargetClock: number;
    stopCompletition: number;
    stopTargetTimer: string;

    constructor(
        private _deviceService: DevicesService,
        private _orderService: OrderService
    ) {
        const oneSecond = 1000;
        this.stopTimer = new Timer({
            onStart: () => this.stopClock = moment().valueOf() - moment(this.stop.beginAt).valueOf(),
            onStop: () => this.stopClock = -1,
            timeoutMs: oneSecond
        });
    }

    async ngOnInit() {
        this.loading = false;
        this.productDetailBar.restart();
        this.stopTimer.reset(true);
        await this.getDataDevice();
        Observable.interval(1000).subscribe(() => {
            if(this.stopCompletition < 100){
                this.stopCompletition = (this.stopClock / (this.stopTargetClock * 2)) * 100;
            }
        })
    }
    async ngOnChanges(changes: SimpleChanges) {
        await this.getDataDevice();
    }

    async ngOnDestroy() {
        this.stopTimer.reset();
    }

    async getDataDevice() {

        const orderFromSatellite: Order = await this._getOrderFromSatellite(this.device);
        const { pieces, targetPieces, order, productCode, startProduction, stopCount } = this._deviceService.getDeviceDataFormatted(this.device, orderFromSatellite);

        this.pieces = pieces;
        this.targetPieces = targetPieces;
        this.productDetailBar.order = this.order = order;
        this.productDetailBar.productCode = this.productCode = productCode;
        this.startProduction = startProduction;
        this.stopCount = stopCount;

        this.percentage = this.targetPieces ? Math.round((this.pieces * 100) / this.targetPieces) : this.defaultPercentage;

        const { totalTime } = this.device.Session;
        const { downtimeWithPlanned } = this.device;
        this.clock = moment(totalTime).valueOf() - moment(downtimeWithPlanned).valueOf();

        this.targetView = true;
        this.stopTargetClock = this.stop.target;
        this.stopTargetTimer = moment.utc(this.stopTargetClock * 60 * 1000).format('HH:mm:ss');
        this.stopCompletition = ((this.stopClock / (this.stopTargetClock * 2)) * 100);
    }

    private async _getOrderFromSatellite(device: Device) {
        let orderOnSatellite: boolean;
        if (device.Company && device.Company.satelliteUrl) {
            orderOnSatellite = await this._deviceService.getDevicePluginOrderOnSatellite(device);
            if (orderOnSatellite && device.Session && device.Session.id) {
                return await this._orderService.getOrderFromSatellite(device);
            }
        }
        return null;
    }
}
