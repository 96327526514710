import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { ControlHistory } from '../models/controlHistory';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class ControlService {
    controlsHistory: ControlHistory[];
    constructor(
        private httpService: HttpService,
        private _envSetting: EnvConfigurationService) { }

    /**
     * Method to get all controlHistory
     *
     */
    async getControls(deviceId?: number) {
        let url = `${this._envSetting.settings.apiBase}/controlHistory?deviceId=${deviceId}`;
        try {
            const response = await this.httpService.get(url)
            .toPromise()

            return response.json() as ControlHistory[]
        } catch (error) {
            return [] as ControlHistory[]
        }
        }
    }