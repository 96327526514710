import { Injectable } from '@angular/core';

export enum MqttCommand {
    registration = '01',
    getInfo = '02',
    events = '10',
    satelliteEvents = '20',
    internal = '$S', // $SYS topics. Using 2-bytes length for parsing convenience.
    powermes = 'powerMES',
    deviceEvents = 'device',
    endpointEvents = 'endpoint'
}

export enum MqttDeviceStateEvent {
    stopProduction = 1,
    startProduction = 2,
    disconnection = 3,
    connection = 4,
    productOrOrderChanged = 5,
    sessionStart = 6,
    sessionStop = 7,
    stopChanged = 8,
    deviceModified = 9,
    startSessionWithProductOrOrder = 10,
    startNightShift = 11,
    startNewShift = 12,
    sessionChanged = 13,
    editActiveSession = 14,
    endAllSessions = 17
}

@Injectable()
export class MqttUtils {
    constructor() { }   
   
    computeTopicForDevice(deviceId: number): string {
        return `10/${deviceId}`;
    }
    
    computeTopicEndpoint(deviceId: number): string {
        return `endpoint/${deviceId}/#`
    }

    getDeviceIdFromTopic(deviceTopic: string): number {
        if(!deviceTopic) return null;
        
        return parseInt(deviceTopic.substr(3), 10);
    }


}