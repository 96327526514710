import {TranslateService} from '@ngx-translate/core';
import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Device} from "../../../models/device";
import {MqttService} from "../../../services/mqtt.service";

@Component({
    selector: 'device-disconnected',
    templateUrl: 'device-disconnected.component.html',
    styleUrls: ['./device-disconnected.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceDisconnectedComponent implements OnInit, OnDestroy {
    @Input() device: Device;

    constructor(
        private _translate: TranslateService,
        private _mqttService: MqttService
    ) {
    }

    async ngOnInit() {
    }

    async ngOnDestroy() {
    }
}
