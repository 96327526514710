export class DocumentView {
    isPdf: boolean;
    payload: string;
    constructor(isPdf, payload){
        this.isPdf = isPdf;
        this.payload = payload;
    }
};

export enum DocumentCategory {
    code_mold = 1,
    control_and_production_plan = 2,
    tooling_instructions = 3, 
    control_instructions = 4, 
    technical_design = 5,
    derogation = 6
};

export enum DocumentViewType {
    single_pdf = 1,
    double_pdf = 2
}