import { Product } from './product';
import { ErrorModel } from 'app/models/error';
export enum StopType {
    changeOver = 0,
    error = 1,
    nonProduction = 2,
    notSpecified = 3,
    disconnection = 4,
    changeWorkProcessTask = 5
}
export class Stop {
    id?: number;
    type?: StopType;
    createdAt: Date;
    beginAt: Date;
    endAt: Date;
    duration: number;
    note: string;
    productId: number;
    deviceId: number;
    errorId: number;
    Product?: Product;
    Error?: ErrorModel;
    isSplit?: boolean;
    splitRef?: number;
    target?: number;
}
