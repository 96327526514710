import {Session} from './session';
import {Product} from './product';
import {ErrorModel} from "./error";
import {Order} from "./order";
import {Company} from "./company";
import {OrderDevice} from "./orderDevice";
import * as moment from "moment/moment";
import { Plugin } from './plugin';
import { WorkProcessInstance } from './work-process';

export enum DeviceState {
    disconnected = 0,
    stopped = 1,
    inProduction = 2
}

export enum SensorType {
    standard = 0,
    delta = 1
}

export interface UniPi {
    id: number;
    serial: string;
    initialized: boolean;
    createdAt: Date;
    updatedAt: Date;
    sensorType: SensorType;
}

export interface ProductionLine {
    id: number;
    plant?: number;
    description: string;
}

export interface StopCounter {
    notSpecified: number,
    planned: number,
    unplanned: number
}

export class Device {
    id: number;
    serial: string;
    location: any;
    sessionType: number;
    createdAt: Date;
    updatedAt: Date;
    address: string;
    categoryId: number;
    state: number;
    place: any;
    plantId?: number;
    MAC?: string;
    label: string;
    companyId: number;
    productionState: number;
    errorDate: Date;
    Unipi: UniPi;
    Session?: Session;
    Product?: Product;
    WorkProcess?: WorkProcessInstance;
    Error?: ErrorModel;
    ProductionLine?: ProductionLine;
    Company?: Company;
    Order?: Order;
    OrderDevice?: OrderDevice;
    multiplier?: number;
    downtime?: number;
    downtimeWithPlanned?: number;
    stopCounter?: StopCounter;
    Plugins?: Plugin[];

    isChangeOver(): boolean {
        return this.state == DeviceState.stopped && !this.Session;
    }

    isProducing(): boolean {
        return this.state == DeviceState.inProduction;
    }

    isStopped(): boolean {
        if (this.WorkProcess) {
            return this.state == DeviceState.stopped && !!this.Session && !!this.Session.WorkProcessCurrentActivityLog;
        } else {
            return this.state == DeviceState.stopped && !!this.Session;
        }
    }

    isChangeActivity(): boolean {
        return this.state == DeviceState.stopped && !!this.Session && !!this.WorkProcess && !this.Session.WorkProcessCurrentActivityLog;
    }

    isConnected(): boolean {
        return this.isProducing() || this.isStopped() || this.isChangeOver() || this.isChangeActivity();
    }

    isTimerMode(): boolean {
        return this.sessionType === 2;
    }

    isProcessMode(): boolean {
        return !!this.WorkProcess;
    }

    getBegin(): string {
        if (moment().toDate().getTime() - moment(this.Session.beginAt).toDate().getTime() > 86400000) {
            return moment().subtract(86400000, "milliseconds").toISOString();
        } else {
            return moment(this.Session.beginAt).toISOString();
        }
    }
}
