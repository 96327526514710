import { ThemeService } from './theme.service';
import { CompanyService } from './company.service';

import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Router } from '@angular/router';

import { Company } from 'app/models/company';
import { User, UserRole } from '../models/user';
import { LocalStorageUtils } from './localstorage.utils';
import { EnvConfigurationService } from './env-config.service';


@Injectable()
export class LoginService {

    token: string;
    constructor(private router: Router, 
        private httpService: HttpService, 
        private _company: CompanyService,
        private _themeService: ThemeService,
        private _localStorageUtils: LocalStorageUtils,
        private _envSetting: EnvConfigurationService) {
        this.loadState();
    }
    /**
     * Function to login a user
     *
     * @param {string} username
     * @param {string} password
     * @returns {string}
     *
     * @memberof LoginService
     */
    async login(email: string, password: string): Promise<boolean> {
        const url = `${this._envSetting.settings.apiBase}/login`;
        try {
            const response = await this.httpService
                .post(url, {
                    email: email,
                    password: password
                })
                .toPromise();
            const user = response.json() as User;

            this._localStorageUtils.user = email;
            this._localStorageUtils.role = user.role;
            
            const xylem = 56;
            if (user.role === UserRole.admin || user.companyId === xylem) {
                this._localStorageUtils.enableDocumentation = true;
            }

            if (user.companyId !== null) {
                this._localStorageUtils.companyId = user.companyId;
            }

            // save name
            if (user.lastName || user.firstName) {
                this._localStorageUtils.lastName = user.lastName ? user.lastName : '';
                this._localStorageUtils.firstName = user.firstName ? user.firstName : '';
            }
            // save token in local storage
            this.setToken(user.token);

            this.httpService.setDefaultHeaders({ 'Authorization': 'bearer ' + this.token });
            return true;
        } catch (err) {
            if (err.status === 401 || err.status === 404) {
                // auth error
                return false;
            } else {
                // other error
                return false;
            }
        }
    }

    /**
     * Function to reset password
     *
     * @param {string} email
     * @returns {string}
     *
     * @memberof LoginService
     */
    async resetPassword(email: string, lang: string) {
        const url = `${this._envSetting.settings.apiBase}/forgotPassword`;
        const data = {
            email: email,
            lang: lang
        }

        const response = await this.httpService.post(url, data).toPromise();
        return response;
    }

    clearState() {
        this._localStorageUtils.clear();
        this.token = undefined;
    }

    readFromLocalStorage(key: string) {
        return localStorage.getItem(key);
    }
    
    /**
     * Set the access token
     *
     * @param {string} token
     *
     * @memberOf LoginService
     */
    setToken(token: string) {
        this._localStorageUtils.token = token;
        this.loadState();
    }

    /**
     * Load auth state from localstorage
     *
     *
     * @memberOf LoginService
     */
    loadState() {
        const token = this._localStorageUtils.token;
        // const user = this._localStorageUtils.user;
        if (token) {
            // user already authenticated
            this.token = token;
            this.httpService.setDefaultHeaders({ 'Authorization': 'bearer ' + this.token });
        }
        this.checkProvider();

        // FIXME: the user should be taken in other ways rather than from /login (i.e. from a /user/me API call)
        // the following code has been disabled since it brokes the pdf report generator
        // if (!user) {
        //     if (token) {
        //         this.logout();
        //     }
        // }
    }

    async logout() {
        const url = `${this._envSetting.settings.apiBase}/logout`;
        try {
            const response = await this.httpService
                .put(url, {})
                .toPromise();
            if (response.status === 200) {
                this.clearState();
                this.router.navigateByUrl('/login');
            } else {
                return;
            }
        } catch (err) {
            if (err.status === 401 || err.status === 404) {
                // auth error
                return false;
            } else {
                // other error
                return false;
            }
        }
    }

    private async checkProvider() {

        const user = this._localStorageUtils.user;
        const role = this._localStorageUtils.role;
        if (user && role !== UserRole.admin) {
            const company: Company = await this._company.getCompany(this._localStorageUtils.companyId);

            if (company.provider) {
                this._themeService.setTeamSystemTheme(company.provider == 2);
            }
        } else if ( user && role === UserRole.admin) {
            this._themeService.setTeamSystemTheme(false);
        }
    }
}