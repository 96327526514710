import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Device } from 'app/models/device';
import { Order } from 'app/models/order';
import { OeeParams } from 'app/models/stats';
import { Stop } from 'app/models/stop';
import { DevicesService } from 'app/services/devices.service';
import { OrderService } from 'app/services/order.service';
import { StopService } from 'app/services/stop-history.service';
import * as moment from 'moment';
import { Timer } from '../device-box/Timer';
import { ProductDetailBarComponent } from '../product-detail-bar/product-detail-bar.component';

@Component({
  selector: 'device-changeactivity',
  templateUrl: './device-changeactivity.component.html',
  styleUrls: ['./device-changeactivity.component.scss']
})

export class DeviceChangeactivityComponent implements OnInit, OnDestroy {
  @Input() device: Device;
  @Input() oee: OeeParams;
  @ViewChild('productDetailBar') productDetailBar: ProductDetailBarComponent;

  defaultPercentage = 70;

  order: string = undefined;
  productCode: string = undefined;

  pieces: number = 0;
  percentage?: number = undefined;
  targetPieces?: number = undefined;
  clock: number;
  changeactivityClock: number;
  activeStop: Stop;
  changeactivityTimer: Timer;
  stopCount: number;

  startProduction;

  constructor(
    private _translate: TranslateService,
    private _stopService: StopService,
    private _deviceService: DevicesService,
    private _orderService: OrderService
  ) {
    const oneSecond = 1000;
    this.changeactivityTimer = new Timer({
      onStart: () => this.changeactivityClock = moment().valueOf() - moment(this.activeStop.beginAt).valueOf(),
      onStop: () => this.changeactivityClock = -1,
      beforeFirstStart: async () => {
        this.activeStop = await this._stopService.getActive(this.device.id);
      },
      timeoutMs: oneSecond
    });
  }

  async ngOnInit() {
    this.productDetailBar.restart();
    this.changeactivityTimer.reset(true);
    this.getDataDevice();
  }

  async ngOnDestroy() {
    this.changeactivityTimer.reset();
  }

  async getDataDevice() {

    const orderFromSatellite: Order = await this._getOrderFromSatellite(this.device);
    const { pieces, targetPieces, order, productCode, startProduction, stopCount } = this._deviceService.getDeviceDataFormatted(this.device, orderFromSatellite);

    this.pieces = pieces;
    this.targetPieces = targetPieces;
    this.productDetailBar.order = this.order = order;
    this.productDetailBar.productCode = this.productCode = productCode;
    this.startProduction = startProduction;
    this.stopCount = stopCount;

    this.percentage = this.targetPieces ? Math.round((this.pieces * 100) / this.targetPieces) : this.defaultPercentage;

    const { totalTime } = this.device.Session;
    const { downtimeWithPlanned } = this.device;
    this.clock = moment(totalTime).valueOf() - moment(downtimeWithPlanned).valueOf()
  }

  private async _getOrderFromSatellite(device: Device) {
    let orderOnSatellite: boolean;
    if (device.Company && device.Company.satelliteUrl) {
      orderOnSatellite = await this._deviceService.getDevicePluginOrderOnSatellite(device);
      if (orderOnSatellite && device.Session && device.Session.id) {
        return await this._orderService.getOrderFromSatellite(device);
      }
    }
    return null;
  }

}
