import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'longDurationPipe'
})
export class LongDurationPipe implements PipeTransform {

    transform(minutes: number): string {
        const daysAbs = Math.floor(minutes / 60 / 24);
        const hoursAbs = Math.floor((minutes - (daysAbs * 60 * 24)) / 60 );
        const minutesAbs = Math.floor(minutes - (hoursAbs + daysAbs * 24) * 60);
        const secondsAbs = Math.floor((minutes - minutesAbs - (hoursAbs + daysAbs * 24) * 60) * 60);

        let ret = '';
        if (daysAbs) {
            ret += daysAbs;
            ret += 'd ';
        }
        if (hoursAbs || daysAbs) {
            ret += hoursAbs;
            ret += 'h ';
        }
        if (minutesAbs || hoursAbs || daysAbs) {
            ret += minutesAbs;
            ret += 'm ';
        }
        ret += secondsAbs;
        ret += 's';

        return ret;
    }

}
