import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'timer'
})
export class TimerPipe implements PipeTransform {

    transform(milliseconds: number): string {
        if (milliseconds < 0) {
            return '';
        }
        const hoursAbs = Math.floor(milliseconds / 3600 / 1000);
        const minutesAbs = Math.floor((milliseconds / 60 / 1000) - (hoursAbs * 60));
        const secondsAbs = Math.floor((milliseconds / 1000) - (minutesAbs * 60) - (hoursAbs * 3600));

        let ret = '';
        if (hoursAbs < 10) {
            ret += '0' + hoursAbs + ':';
        } else {
            ret += hoursAbs + ':';
        }
        if (minutesAbs < 10) {
            ret += '0' + minutesAbs + ':';
        } else {
            ret += minutesAbs + ':';
        }
        if (secondsAbs < 10) {
            ret += '0' + secondsAbs;
        } else {
            ret += secondsAbs;
        }
        return ret;
    }

}