import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Device } from "../../../models/device";
import { MqttService } from "../../../services/mqtt.service";
import { ProductDetailBarComponent } from "../product-detail-bar/product-detail-bar.component";
import { OeeParams } from "../../../models/stats";
import { DevicesService } from "../../../services/devices.service";
import * as moment from "moment";
import { ProgressBarComponent } from "../progress-bar/progress-bar.component";
import { Timer } from "../device-box/Timer";
import { OrderService } from '../../../services/order.service';
import { Order } from '../../../models/order';
import { Observable } from 'rxjs/Rx';
import { StatsService } from '../../../services/stats.service';
import { MqttUtils } from 'app/services/mqtt.utils';

const oneSecond = 1000;
const tenMinutes = 10 * 60 * 1000;

@Component({
    selector: 'device-producing',
    templateUrl: 'device-producing.component.html',
    styleUrls: ['./device-producing.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DeviceProducingComponent implements OnInit, OnDestroy {
    @Input() device: Device;
    @Input() mqttService: MqttService;
    @Input() oee: OeeParams;
    @ViewChild('productDetailBar') productDetailBar: ProductDetailBarComponent;

    defaultPercentage = 70;

    order: string = undefined;
    productCode: string = undefined;

    downTime: number = 0;

    pieces: number = 0;
    percentage?: number = undefined;
    targetPieces?: number = undefined;
    stopCount: number = 0;

    productionTimer: Timer;
    productionClock = -1;
    productionBeginAt;

    targetSpeed: number = 0;
    realSpeed: number = 0;

    startProduction;
    timeProduction;
    targetEndProduction;
    realEndProduction;

    constructor(
        private _deviceService: DevicesService,
        private _orderService: OrderService,
        private _statsService: StatsService,
        private _mqttUtils: MqttUtils
    ) {

        //     this.productionTimer = new Timer({
        //         onStart: () => {
        //             this.productionClock += oneSecond;
        //         },
        //         onStop: () => this.productionClock = -1,
        //         beforeFirstStart: () => {
        //             if (this.device.isProducing()) {
        //                 const { totalTime } = this.device.Session;
        //                 const { downtimeWithPlanned } = this.device;
        //                 this.productionClock = moment(totalTime).valueOf() - moment(downtimeWithPlanned).valueOf();
        //             }
        //         },
        //         timeoutMs: oneSecond
        //     });

        this.productionTimer = new Timer({
            onStart: () => {
                this.productionClock = (moment().valueOf() - moment(this.productionBeginAt).valueOf()) - moment(this.device.downtimeWithPlanned).valueOf();
            },
            onStop: () => this.productionClock = -1,
            beforeFirstStart: () => {
                if (this.device.isProducing()) {
                    const { totalTime } = this.device.Session;
                    this.productionBeginAt = moment().valueOf() - moment(totalTime).valueOf();
                }
            },
            timeoutMs: oneSecond
        });
    }

    async ngOnInit() {
        this.productDetailBar.restart();
        this.getDataDevice();
        await this.waitForPieces(1);

        Observable.interval(1000).subscribe(() => {
            this.timeProduction += 1000;
        });

        Observable.interval(tenMinutes).subscribe(async () => {
            await this.getOee();
        });
    }

    async ngOnDestroy() {
        this.productionTimer.reset();
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async waitForPieces(retry: number) {
        if (retry >= 10) {
            console.log("You have reached maximum number of retry without success");
            return;
        }
        if (!this.mqttService.client) {
            await this.sleep(2000);
            await this.waitForPieces(retry + 1);
        } else {
            this.mqttService.client.on('message', async (topic: string, payload: Buffer) => {
                const command = parseInt(topic.substr(0, 2), 10);
                const deviceId = this._mqttUtils.getDeviceIdFromTopic(topic);
                if (command === 10 && deviceId === this.device.id) {
                    const commandType = payload.readUIntBE(0, 1);
                    if (commandType === 1) {
                        let count = payload.readUIntBE(5, 1); // Boxing conversion converts `byte` to `Byte`
                        this.updateValue(true, count);
                    }
                }
            });
        }
    }

    updateValue(newPiece: boolean, count: number) {
        if (newPiece) {
            this.pieces += (count * this.getMultiplier());
            this.device.Session.itemsTotal += (count * this.getMultiplier());
        }
        if (this.targetPieces) {
            this.percentage = Math.round((this.pieces * 100) / this.targetPieces);
        }

        this.timeProduction = moment().valueOf() - moment(this.device.Session.beginAt).valueOf()
        let productionTimeElapsed = moment(this.timeProduction).diff(this.device.downtimeWithPlanned) / (1000 * 60);
        this.realSpeed = Math.round((this.device.Session.itemsTotal / productionTimeElapsed) * 60);
        this.realEndProduction = moment().add((((this.targetPieces - this.pieces) / (this.realSpeed) * 60 * 60 * 1000)), 'milliseconds').toDate();
    }

    getMultiplier() {
        let multiplier = this.device.multiplier || 1;
        if (this.device.Product && this.device.Product.multiplier) {
            multiplier = this.device.Product.multiplier;
        }
        return multiplier;
    }

    async getDataDevice() {

        const orderFromSatellite: Order = await this._getOrderFromSatellite(this.device);
        const {
            pieces,
            targetPieces,
            targetSpeed,
            order,
            productCode,
            startProduction,
            downTime,
            stopCount
        } = this._deviceService.getDeviceDataFormatted(this.device, orderFromSatellite);

        this.pieces = pieces; this.targetPieces = targetPieces; this.targetSpeed = targetSpeed;
        this.startProduction = startProduction; this.downTime = downTime; this.stopCount = stopCount;

        this.productDetailBar.order = this.order = order;
        this.productDetailBar.productCode = this.productCode = productCode;

        if (!this.targetPieces) {
            this.percentage = this.defaultPercentage;
        }

        this.targetEndProduction = this.targetPieces ? moment(this.startProduction).add(Math.round(this.targetPieces / (this.targetSpeed / 60)), 'minutes').toDate() : undefined;

        this.timeProduction = this.device.Session.totalTime;
        this.updateValue(false, 0);

        this.productionTimer.reset(this.device.isTimerMode());
    }

    async getOee() {
        this.oee = await this._statsService.getAggregateOee(this.device.getBegin(), moment().toISOString(), [this.device.id]);
        this.oee.oee *= 100;
    }

    private async _getOrderFromSatellite(device: Device) {
        let orderOnSatellite: boolean;
        if (device.Company && device.Company.satelliteUrl) {
            orderOnSatellite = await this._deviceService.getDevicePluginOrderOnSatellite(device);
            if (orderOnSatellite && device.Session && device.Session.id) {
                return await this._orderService.getOrderFromSatellite(device);
            }
        }
        return null;
    }
}
