import { PipeTransform, Pipe  } from '@angular/core';

@Pipe({
  name: 'slice'
})
export class SlicePipe implements PipeTransform {

  transform(value: string, limit: number): string {
    return value.length > limit ? value.slice(0, limit).concat('...') : value;
  }
}
