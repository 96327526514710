import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable()
export class UiService {
    constructor(private _snackBar: MatSnackBar) { }

    openSnackBar(message: string) {
        const snackBar = this._snackBar.open(message);

        setTimeout(() => {
            snackBar.dismiss();
        }, 3000);
    }
    openErrorSnackBar(message: string, action?: string) {
        const config = new MatSnackBarConfig();
        config.panelClass = ['snackbar-error-class'];
        const snackBar = this._snackBar.open(message, action ? action : undefined, config);

        // setTimeout(() => {
        //     snackBar.dismiss();
        // }, 3000);
    }
}