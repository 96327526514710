import { PipeTransform, Pipe  } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
  transform(items: any[], positions: any[]): any {
      if (!items || !positions) {
          return items;
      }
      return items.slice(positions[0], positions[1]);
  }
}

