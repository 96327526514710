import { Component, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-popup-error',
  templateUrl: './popup-error.component.html',
  styleUrls: ['./popup-error.component.scss'],
  encapsulation: ViewEncapsulation.None})

export class PopupErrorComponent {

  error: string;

  constructor( private dialogRef: MatDialogRef<PopupErrorComponent>){

  }

  closeDialog(){
    this.dialogRef.close();
  }

}

// @Component({
//   selector: 'popup-error-dialog',
//   templateUrl: '/popup-error-dialog.html',
//   styleUrls: ['popup-error.component.scss'],
//   encapsulation: ViewEncapsulation.None
// })
// export class PopupErrorComponentDialog {

//   constructor( private dialogRef: MatDialogRef<PopupErrorComponentDialog>){

//   }

//   closeDialog(){
//     this.dialogRef.close();
//   }

// }
