import { ActiveFiltersService } from '../../../services/active-filters.service';
import { Router } from '@angular/router';
import { DevicesService } from '../../../services/devices.service';
import { Device } from '../../../models/device';
import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MqttService, MqttEvent } from '../../../services/mqtt.service';
import { LocalStorageUtils } from 'app/services/localstorage.utils';

@Component({
    selector: 'app-filter-bar',
    templateUrl: './filter-bar.component.html',
    styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {

    // to lock data request if is already processing
    private _filterSubscription;

    @Input() startDate: Date;
    @Input() endDate: Date;
    devicesList: Device[];
    productionLinesDeviceList: Device[];
    private countShow = 0;
    showUpdate = false;
    showTimeout: any;


    constructor(
        public activeFilters: ActiveFiltersService,
        private _deviceService: DevicesService,
        private _router: Router,
        private _location: Location,
        private _localStorageUtils: LocalStorageUtils,
        private _mqttService: MqttService
    ) { }

    /**
     * Triggered when the date is changed
     *
     * @param {any} res
     *
     * @memberOf FilterBarComponent
     */
    dateChanged(res) {
        this.activeFilters.dateBegin = res[0];
        this.activeFilters.dateEnd = res[1];
        this.filterChanged();
    }

    /**
     * Triggered when a filter is changed
     *
     *
     * @memberOf FilterBarComponent
     */
    filterChanged(isDocumnetPage?: boolean) {
        this.activeFilters.emitChangeEvent(false, isDocumnetPage);
    }

    /**
     * Check if the current page is the home page
     *
     * @memberof FilterBarComponent
     */
    public isRootPage(): boolean {
        return this._router.isActive('/realtime', true) || this._router.isActive('/realtime-line', true);
    }

    /**
     * Check if the current page is the stop page
     *
     * @memberof FilterBarComponent
     */
    public isStopPage(): boolean {
        return this._router.isActive('/stops', true);
    }

    /**
     * Check if the current page is the qa pages
     *
     * @memberof FilterBarComponent
     */
    public isQualityControl(): boolean {

        if (this._router.isActive('/qa-product', true) || 
                this._router.isActive('/qa-detail', true)) {

            return true;

        } else return false;
    }

    /**
     * Check if the current page is the quality controls page
     *
     * @memberof FilterBarComponent
     */
    public isQualityControlPage(): boolean {

        if (this._router.isActive('/qualitycontrol', true)) {

            return true;

        } else return false;
    }

    /**
     * Check if the current page is the documentation page
     *
     * @memberof FilterBarComponent
     */
    public isDocumentationPage(): boolean {

        if (this._router.isActive('/documentview', true)) {

            return true;

        } else return false;
    }

    /**
     * Check if the current page is the documentation page
     *
     * @memberof FilterBarComponent
     */
    public isControlPage(): boolean {

        if (this._router.url.includes('/qualitycontrol/control')) {

            return true;

        } else return false;
    }

    public isQualityDashboard(): boolean {
        return this._router.isActive('/qa-dashboard', true) ? true : false;
    }

    /**
     * Disable product filter if there is the condition
     *
     * @memberof FilterBarComponent
     */
    public disableFilter(): boolean {
        if (!this._router.isActive('/oee', true) && !this._router.isActive('/oee/trends', true)) {
            return false;
        } else {
            return true;
        }
    }

    goBack() {
        if (this._router.isActive('/oee/trends', true)) {
            this._router.navigateByUrl('/oee');
        } else {
            if (this.activeFilters.device.ProductionLine) {
                this._router.navigateByUrl('/realtime-line');
            } else {
                this._router.navigateByUrl('/realtime');
            }
        }
    }

    goBackControl() {
        this._location.back();
    }

    goAggregates() {
        this._router.navigateByUrl('/lines');
    }
    async ngOnInit() {

        // subscribe to device changed event
        this._filterSubscription = this.activeFilters.onFilterChanged.subscribe(() => {
            this.onFilterChanged();
        });

        this.devicesList = await this._deviceService.getDevices();
        this._reBuildDeviceList();
        const isDocumentPage =  window.location.pathname === '/documentview';
        this.activeFilters.emitChangeEvent(null, isDocumentPage);

    }

    onFilterChanged() {
        if (this.devicesList) {
            this._reBuildDeviceList();
        } else return;
    }

    /**
     * Rebuilt device list whith production Line
     *
     * @memberof FilterBarComponent
     */
    public _reBuildDeviceList() {
        let productionLines = this.devicesList.filter((device) => device.ProductionLine);

        const activeDevice = this.activeFilters.device || this.devicesList
        .find((item) => item.id === this._localStorageUtils.deviceId);
        
        this.devicesList = this.devicesList.filter((device) => !device.ProductionLine);

        let lines = [];

        if ((activeDevice && activeDevice.ProductionLine)) {
            activeDevice.label = activeDevice.ProductionLine.description;
            lines.push(activeDevice.ProductionLine);
            this.devicesList.push(activeDevice);
        }

        for (let device of productionLines) {
            if (!lines.find((item) => item.id === device.ProductionLine.id)) {
                device.label = device.ProductionLine.description;
                lines.push(device.ProductionLine);
                this.devicesList.push(device);
            };
        };
    }

    /**
     * Check if the current user is admin.
     *
     *
     * @memberof FilterBarComponent
     */
    isAdmin() {
        return this._localStorageUtils.user === 'admin@techmass.it';
    }

    /**
     * Check if the current page is line page.
     *
     *
     * @memberof FilterBarComponent
     */
    isLinePage() {
        return this._router.isActive('realtime-line', true);
    }

    async updateDevice() {
        this.countShow++;
        clearTimeout(this.showTimeout);
        if (this.countShow < 4) {
            this.showUpdate = false;
            this.showTimeout = setTimeout(() => {
                this.countShow = 0;
                this.showUpdate = false;
            }, 500);
        } else {
            this.showUpdate = true;
            this.showTimeout = setTimeout(() => {
                this.countShow = 0;
                this.showUpdate = false;
            }, 30000);
            if (this.countShow > 4) {
                const device = await this._deviceService.getDevice(this.activeFilters.deviceId);
                this._mqttService.notifyEvent(device.Unipi, device, MqttEvent.updateDevice);
            }
        }
    }
};