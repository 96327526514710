import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';
import { SnackbarType } from '../models/snackbar';

@Injectable()
export class SnackbarService {
    private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    private verticalPosition: MatSnackBarVerticalPosition = 'top';
    private duration: number = 5000;
    
    
    constructor(public snackBar: MatSnackBar) { }

    showSnackbar(message: string = '', type: SnackbarType = SnackbarType.info, options?:any) {
        let config = this._computeConfig(type, options);
        this.snackBar.open(message, undefined, config);
    }

    private _computeConfig(type: SnackbarType = SnackbarType.info, options?): MatSnackBarConfig {
        let config = new MatSnackBarConfig();
        
        config.duration = this.duration;
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        
        switch(type) {
            case SnackbarType.success:
                config.panelClass =  ['generic-snackbar', 'success-snackbar'];  
                break; 
            case SnackbarType.error: 
                config.panelClass =  ['generic-snackbar', 'error-snackbar'];
                break;
            case SnackbarType.info:
            default:
                config.panelClass =  ['generic-snackbar', 'info-snackbar'];  
                break;
            
        }

        if(options) {
            if(options.duration) {
                config.duration = options.duration;
            }
            if(options.verticalPosition) {
                config.verticalPosition = options.verticalPosition;
            }
            if(options.horizontalPosition) {
                config.horizontalPosition = options.horizontalPosition;
            }
        }

        return config;
    }
}

