import { Injectable } from '@angular/core';

@Injectable()
export class NavbarService {
    title = 'dashboard';
    constructor() { }

    /**
     * Function to set navbar title
     *
     * @param {any} newTitle
     * @memberof NavbarService
     */
    setTitle(newTitle) {
        this.title = newTitle;
    }
}
