import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageUtils {
    constructor() { }   

    /**
     * Write data in local storage.
     *
     * @param {string} key
     * @param {string} data
     *
     * @memberof LocalStorageUtils
     */
     writeInLocalStorage(key: string, data: any) {
        localStorage.setItem(key, data);
        return;
    }

    /**
     * Read data from local storage
     *
     * @param {string} key
     *
     * @memberof LocalStorageUtils
     */
     readFromLocalStorage(key: string) {
        return localStorage.getItem(key);
    }

    clear() {
        localStorage.clear();
    }

    set role(_role: number) {
        this.writeInLocalStorage('role', _role);
    }

    get role(): number {
        try {
            return parseInt(this.readFromLocalStorage('role'), 10);
        } catch (ex) {
            return null;
        }
    }
    
    set companyId(_companyId: number) {
        this.writeInLocalStorage('companyId', _companyId);
    }

    get companyId(): number {
        try {
            return parseInt(this.readFromLocalStorage('companyId'), 10);
        } catch (ex) {
            return null;
        }
    }
    
    set deviceId(_deviceId: number) {
        this.writeInLocalStorage('deviceId', _deviceId);
    }

    get deviceId(): number {
        try {
            return parseInt(this.readFromLocalStorage('deviceId'), 10);
        } catch (ex) {
            return null;
        }
    }
    
    set granularity(_granularity: number) {
        this.writeInLocalStorage('granularity', _granularity);
    }

    get granularity(): number {
        try {
            return parseInt(this.readFromLocalStorage('granularity'), 10);
        } catch (ex) {
            return null;
        }
    }
    
    set productId(_productId: number) {
        this.writeInLocalStorage('productId', _productId);
    }

    get productId(): number {
        try {
            return parseInt(this.readFromLocalStorage('productId'), 10);
        } catch (ex) {
            return null;
        }
    }

    set user(_user: string) {
        this.writeInLocalStorage('user', _user);
    }

    get user(): string {
        try {
            return this.readFromLocalStorage('user');
        } catch (ex) {
            return null;
        }
    }
    set lang(_lang: string) {
        this.writeInLocalStorage('lang', _lang);
    }

    get lang(): string {
        try {
            return this.readFromLocalStorage('lang');
        } catch (ex) {
            return null;
        }
    }

    set token(_token: string) {
        this.writeInLocalStorage('token', _token);
    }

    get token(): string {
        try {
            return this.readFromLocalStorage('token');
        } catch (ex) {
            return null;
        }
    }

    set firstName(_firstName: string) {
        this.writeInLocalStorage('firstName', _firstName);
    }

    get firstName(): string {
        try {
            return this.readFromLocalStorage('firstName');
        } catch (ex) {
            return null;
        }
    }
    
    set lastName(_lastName: string) {
        this.writeInLocalStorage('lastName', _lastName);
    }

    get lastName(): string {
        try {
            return this.readFromLocalStorage('lastName');
        } catch (ex) {
            return null;
        }
    }

    set enableDocumentation(_enableDocumentation: boolean) {
        this.writeInLocalStorage('enableDocumentation', _enableDocumentation);
    }

    get enableDocumentation(): boolean {
        try {
            return Boolean(this.readFromLocalStorage('enableDocumentation'));
        } catch (ex) {
            return null;
        }
    }
   
    set showDisconnected(_showDisconnected: boolean) {
        this.writeInLocalStorage('showDisconnected', _showDisconnected);
    }

    get showDisconnected(): boolean {
        try {
            return Boolean(this.readFromLocalStorage('showDisconnected'));
        } catch (ex) {
            return null;
        }
    }

    set enableQualityControl(_enableQualityControl: boolean) {
        this.writeInLocalStorage('enableQualityControl', _enableQualityControl);
    }

    get enableQualityControl(): boolean {
        try {
            return Boolean(this.readFromLocalStorage('enableQualityControl'));
        } catch (ex) {
            return null;
        }
    }
}