import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { throttle } from 'lodash';
import { ControlHistory } from 'app/models/controlHistory';
import { Device } from 'app/models/device';
import { TaskHistory } from 'app/models/taskHistory';
import { ActiveFiltersService } from 'app/services/active-filters.service';
import { ControlService } from 'app/services/control.service';
import { DevicesService } from 'app/services/devices.service';
import { MqttEvent, MqttService } from 'app/services/mqtt.service';
import { ThemeService } from 'app/services/theme.service';
import { PageWithLoader } from '../page-with-loader';
import { LocalStorageUtils } from 'app/services/localstorage.utils';
import { MqttCommand, MqttDeviceStateEvent, MqttUtils } from 'app/services/mqtt.utils';

interface row{
  id: number;
  Quality_check: string;
  executed: string;
  finished: boolean;
  productId: number;
}

@Component({
  templateUrl: './quality-control.component.html',
  styleUrls: ['./quality-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QualityControlComponent extends PageWithLoader implements OnInit, OnDestroy {

  device: Device;
  controlHistory: ControlHistory[];
  private _filterSubscription;
  loading = true;
  currentProductId = null;
  availableData = false;
  rows = [];

  columns = [{ prop: 'Quality_check' }, { name: 'Executed' }, { name: 'Action' }];

  throttleSendData = throttle(this.sendDataDevice, 2000);

  constructor(
    private _deviceService: DevicesService,
    private _mqttService: MqttService,
    private _activeFilterService: ActiveFiltersService,
    private _controlService: ControlService,
    private _router: Router,
    private _mqttUtils: MqttUtils,
    private _localStorageUtils: LocalStorageUtils,
    _themeService: ThemeService
  ) {
    super(_themeService);
  }

  async ngOnInit() {
    // subscribe to controls changed event
    this._filterSubscription = this._activeFilterService.onFilterChanged.subscribe( () => {
      this.onFilterChanged();
    });

    await this._activeFilterService.initializeActiveDevice();
    await this.onFilterChanged();
    await this.waitData();
  }

  ngOnDestroy() {
    if (this._filterSubscription) {
      this._filterSubscription.unsubscribe();
    }
    console.log(`unsubscribe from ${this._mqttUtils.computeTopicForDevice(this.device.id)}`);
    console.log(`unsubscribe from ${this._mqttUtils.computeTopicEndpoint(this.device.id)}`);
    this._mqttService.unsubscribe(this._mqttUtils.computeTopicForDevice(this.device.id));
    this._mqttService.unsubscribe(this._mqttUtils.computeTopicEndpoint(this.device.id));
  }

  //Assign class to datatable rows

  getRowClass(row) {
    if (row.finished) {
      return {
        'row-finished': true
      };
    }

    return {
      'row-not-finished': true
    };

  }

  navigate(path) {
    this._router.navigateByUrl(path);
  }


  createDataRows(controlHistory:ControlHistory[]): row[]{
    let rows = [];
    controlHistory.forEach(control => {
      if (control.productId === this._activeFilterService.productId || this._activeFilterService.productId === 0) {
        let executedTasks = this.checkExecuted(control.Tasks);

        let row : row =  {
          id: control.id,
          Quality_check: control.Control.description,
          executed: executedTasks + "/" + control.Tasks.length,
          finished: executedTasks == control.Tasks.length ? true : false,
          productId: control.productId
        }
        rows.push(row);
      }
    });

    return rows
  }

  checkExecuted(tasks:TaskHistory[]){
    let done: number = 0;

    tasks.forEach(task => {
      if(!task.completed || task.notDone){
        done;
      } else {
        done++;
      }
    });

    return done;
  }

  async sendDataDevice() {
    this.device = await this._deviceService.getDevice(this.device.id);
    this.currentProductId = this.device.Product ? this.device.Product.id : null;
    try {
      this.controlHistory = await this._controlService.getControls(this.device.id);
      this.rows = this.createDataRows(this.controlHistory);
    } catch (error) {

    }
  }

  //Filter functions

  async onFilterChanged() {
    this.loading = true;
    if (this.device) {
      console.log(`unsubscribe from ${this._mqttUtils.computeTopicForDevice(this.device.id)}`);
      console.log(`unsubscribe from ${this._mqttUtils.computeTopicEndpoint(this.device.id)}`);
      await this._mqttService.unsubscribe(this._mqttUtils.computeTopicForDevice(this.device.id));
      await this._mqttService.unsubscribe(this._mqttUtils.computeTopicEndpoint(this.device.id));
    }
    let devId = this._localStorageUtils.deviceId;
    this.device = await this._deviceService.getDevice(devId);
    this.rows = [];
    await this.subscribeToDataChannel(1);
    // await this._mqttService.subscribe('10/' + this.device.id);
    this.currentProductId = this.device.Product ? this.device.Product.id : null;
    // if (this.currentProductId) {
      try {
        this.controlHistory = await this._controlService.getControls(devId);
        this.rows = this.createDataRows(this.controlHistory); 
      } catch (error) {
        
      }
    // }
    this.loading = false;
    //this.rows = [];
  }

  async subscribeToDataChannel(retry: number) {
    if(retry >= 10) {
        console.log("You have reached maximum number of retry without success");
        return;
    }
    if(!this._mqttService.isConnected()) {
        await this.sleep(2000);
        await this.subscribeToDataChannel(retry + 1);
    } else {
        await this._mqttService.subscribe(this._mqttUtils.computeTopicForDevice(this.device.id));
        console.log(`subscribe to ${this._mqttUtils.computeTopicForDevice(this.device.id)}`);
        await this._mqttService.subscribe(this._mqttUtils.computeTopicEndpoint(this.device.id));
        console.log(`subscribe to ${this._mqttUtils.computeTopicEndpoint(this.device.id)}`);
    }
}

sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

  async waitData() {
    this._mqttService.client.on('message', async (topic: string, payload: Buffer) => {
      if (topic.substring(0, topic.indexOf('/')) === MqttCommand.endpointEvents) {
        const jsonPayload = JSON.parse(payload.toString());
        if (topic.includes('production/end') || jsonPayload.event === MqttDeviceStateEvent.endAllSessions || jsonPayload.event === MqttDeviceStateEvent.startProduction) {
          this._activeFilterService.emitChangeEvent();
          this.throttleSendData();
          // await this._activeFilterService._refetchProductsList();
        }
      } else {
        const command = parseInt(topic.substr(0, 2), 10);
        const deviceId = this._mqttUtils.getDeviceIdFromTopic(topic);
        if (command === 10 && deviceId === this.device.id) {
            const commandType = payload.readUIntBE(0, 1);
            if (commandType === 50) {
              const eventType = payload.readUIntBE(1, 1);
              if ((eventType === 1 || eventType === 2)) {
                this.throttleSendData();
              }
            } else if (commandType === 40) {
              const eventType = payload.readUIntBE(1, 1);
              if ((eventType === 1 || eventType === 5 || eventType === 7 || eventType === 10 || eventType === 13)) {
                this.throttleSendData();
              }
            } 
        }
      }
    });
}

}
