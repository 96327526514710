import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Plugin } from '../models/plugin';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class PluginService {
    private _baseUrl = 'plugins';

    constructor(
        private _http: HttpService,
        private _envSetting: EnvConfigurationService
    ) { }

    async getPlugins(deviceId: number) {
        const url = `${this._envSetting.settings.apiBase}/${this._baseUrl}/${deviceId}`;
        const response = await this._http.get(url).toPromise() 

           return response.json() as Plugin[];
        }
}