import { PluginDevice } from './pluginDevice';

export enum PluginType {
    order='order',
    changeItems='changeItems',
    showDelta='showDelta',
    doneOrder='doneOrder',
    operator='operator',
    print='print',
    orderList='orderList',
    multiOrders='multiOrders',
    batch='batch',
    pdfDocs='pdfDocs',
    checks='checks',
    operatorLogin='operatorLogin'
}

export interface Plugin {
    id: number;
    code: string;
    description?: string;
    createdAt?: Date;
    updatedAt?: Date;
    PluginsDevices?: PluginDevice;
}