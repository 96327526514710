interface TimerParams {
    onStart: () => void;
    timeoutMs: number
    onStop?: () => void;
    beforeFirstStart?: () => void;
}

export class Timer {
    private timeoutMs: number;
    private interval: number;
    private onStart: () => void  = () => {};
    private onStop: () => void  = () => {};
    private beforeFirstStart: () => void = () => {};

    constructor(params: TimerParams) {
        this.timeoutMs = params.timeoutMs;
        this.onStart = params.onStart;
        this.onStop = params.onStop ? params.onStop : () => {};
        this.beforeFirstStart = params.beforeFirstStart ? params.beforeFirstStart : () => {};
    }

    reset(startCondition: boolean = false) {
        if (startCondition) {
            this.start();
        } else {
            this.stop();
        }
    }

    private async start() {
        if (this.interval) {
            this.stop();
        }

        await this.beforeFirstStart();

        await this.onStart();

        this.interval = setInterval(this.onStart, this.timeoutMs) as unknown as number;
    }

    private async stop() {
        if (this.interval) {
            clearInterval(this.interval);
            await this.onStop();
            this.interval = null;
        }
    }
}
