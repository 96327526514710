import { LoginService } from './services/login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ActiveFiltersService } from './services/active-filters.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment/moment';
import { TranslateService } from '@ngx-translate/core';
import {MqttService} from "./services/mqtt.service";
import { LocalStorageUtils } from './services/localstorage.utils';

declare var ga: any;

@Component({
    selector: 'app-root',
    template: `
    <app-navbar></app-navbar>
    <div>
      <router-outlet></router-outlet>
    </div>
  `,
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
      private _activeFilters: ActiveFiltersService,
      private _activatedRoute: ActivatedRoute,
      private _loginService: LoginService,
      private _translate: TranslateService,
      private _router: Router,
      private _localStorageUtils: LocalStorageUtils,
      private _mqttService: MqttService
    ) {
        const lang = this._localStorageUtils.lang;

        _translate.setDefaultLang(lang || 'en');
        _translate.use(lang || 'en');

        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (window["ga"]) {
                    ga('set', 'page', event.urlAfterRedirects);
                    ga('send', 'pageview');
                }
            }
        });
    }

    async ngOnInit() {
        if (this._localStorageUtils.deviceId) {
            this._activeFilters.deviceId = this._localStorageUtils.deviceId;
        }
        if (this._localStorageUtils.productId) {
            this._activeFilters.productId = this._localStorageUtils.productId;
        }
        if (this._localStorageUtils.granularity) {
            this._activeFilters.granularity = this._localStorageUtils.granularity;
        }
        if (this._localStorageUtils.readFromLocalStorage('dateBegin')) {
            this._activeFilters.dateBegin = moment(this._localStorageUtils.readFromLocalStorage('dateBegin')).toDate();
        }
        if (this._localStorageUtils.readFromLocalStorage('dateEnd')) {
            this._activeFilters.dateEnd = moment(this._localStorageUtils.readFromLocalStorage('dateEnd')).toDate();
        }

        // set a default time filter
        if (!this._activeFilters.dateBegin || !this._activeFilters.dateEnd) {
            this._activeFilters.setDateFromIntervalString('today');
        }

        // get params from query filter
        this._activatedRoute.queryParams.subscribe(params => {
            if (params['token']) {
                this._loginService.setToken(params['token']);
            }
            if (params['deviceId']) {
                this._activeFilters.deviceId = parseInt(params['deviceId'], 10);
            }
            if (params['dateBegin']) {
                this._activeFilters.dateBegin = new Date(params['dateBegin']);
            }
            if (params['dateEnd']) {
                this._activeFilters.dateEnd = new Date(params['dateEnd']);
            }
        });

        if (this._localStorageUtils.token && !this._mqttService.isConnected()) {
            try {
                await this._mqttService.connect(this._localStorageUtils.readFromLocalStorage('user'), this._localStorageUtils.readFromLocalStorage('token'));
            } catch (err) {
                console.error(err);
            }
        }
    }
}