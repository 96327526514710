import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-detail-bar',
  templateUrl: './product-detail-bar.component.html',
  styleUrls: ['./product-detail-bar.component.scss']
})
export class ProductDetailBarComponent implements OnInit {
    @Input() order : string;
    @Input() productCode: string;
    @Input() control?: string;

    @Input() nextOrder? : string;
    @Input() nextProductCode?: string;

    constructor() { }

    ngOnInit() {
    }

    restart() {
      this.order = null;
      this.productCode = null;
      this.nextOrder = null;
      this.nextProductCode = null;
    }

}
