import { Product } from '../models/product';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
import { ResponseContentType } from '@angular/http';
import { EnvConfigurationService } from './env-config.service';

@Injectable()
export class ProductService {
    constructor(
        private httpService: HttpService,
        private _envSetting: EnvConfigurationService) { }

    /**
     * Method to get all products
     *
     * @memberof ProductService
     */
    async getProducts(deviceId?: number, dateFrom?: string, dateTo?: string): Promise<Product[]> {
        let url = `${this._envSetting.settings.apiBase}/product?deviceId=${deviceId}`;

        if (dateFrom && dateTo) {
            url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
        }

        const response = await this.httpService
            .get(url)
            .toPromise();
        return response.json() as Product[];
    }

    /**
     * retrieve document about product
     *
     * @param {number} companyId
     * @param {string} productCode
     * @param {string} docName
     * @returns
     * @memberof ProductService
     */
    async getProductDocs(companyId: number, plantId: number, productCode: string, docName: string){
        let url = `${this._envSetting.settings.apiBase}/product/productdocs?companyId=${companyId}&plantId=${plantId}&productCode=${productCode}&docName=${docName}`;
        const response = await this.httpService
            .get(url, { responseType: ResponseContentType.Blob })
            .toPromise();
        return response;
    }
}
